/**
 * DO NOT MANUALLY EDIT THIS FILE
 * This file is generated automatically by the build script on prebuild step.
 * Please refer to documentation for more information
 */
export const messages = {
  'locales.en-GB': 'English',
  'locales.de-DE': 'Deutsch',
  'locales.fr-FR': 'Français',
  'stores.uk': 'UK (GBP)',
  'stores.eu': 'EU (EUR)',
  'stores.us': 'US (USD)',
  'countries.A1': 'Anonymisierer',
  'countries.A2': 'Satellitenanbieter',
  'countries.AD': 'Andorra',
  'countries.AE': 'Vereinigte Arabische Emirate',
  'countries.AF': 'Afghanistan',
  'countries.AG': 'Antigua und Barbuda',
  'countries.AI': 'Anguilla',
  'countries.AL': 'Albanien',
  'countries.AM': 'Armenien',
  'countries.AN': 'Niederländische Antillen',
  'countries.AO': 'Angola',
  'countries.AP': 'Region Asien/Pazifik',
  'countries.AQ': 'Antarktis',
  'countries.AR': 'Argentinien',
  'countries.AS': 'Amerikanisch-Samoa',
  'countries.AT': 'Österreich',
  'countries.AU': 'Australien',
  'countries.AW': 'Aruba',
  'countries.AX': 'Aland-Inseln',
  'countries.AZ': 'Aserbaidschan',
  'countries.BA': 'Bosnien und Herzegowina',
  'countries.BB': 'Barbados',
  'countries.BD': 'Bangladesch',
  'countries.BE': 'Belgien',
  'countries.BF': 'Burkina Faso',
  'countries.BG': 'Bulgarien',
  'countries.BH': 'Bahrain',
  'countries.BI': 'Burundi',
  'countries.BJ': 'Benin',
  'countries.BM': 'Bermuda',
  'countries.BN': 'Brunei Darussalam',
  'countries.BO': 'Bolivien',
  'countries.BR': 'Brasilien',
  'countries.BS': 'Bahamas',
  'countries.BT': 'Bhutan',
  'countries.BV': 'Bouvetinsel',
  'countries.BW': 'Botswana',
  'countries.BY': 'Belarus',
  'countries.BZ': 'Belize',
  'countries.CA': 'Kanada',
  'countries.CC': 'Kokosinseln (Keelinginseln)',
  'countries.CD': 'Kongo',
  'countries.CF': 'Zentralafrikanische Republik',
  'countries.CG': 'Kongo',
  'countries.CH': 'Schweiz',
  'countries.CI': 'Elfenbeinküste',
  'countries.CK': 'Cookinseln',
  'countries.CL': 'Chile',
  'countries.CM': 'Kamerun',
  'countries.CN': 'China',
  'countries.CO': 'Kolumbien',
  'countries.CR': 'Costa Rica',
  'countries.CU': 'Kuba',
  'countries.CV': 'Kap Verde',
  'countries.CX': 'Weihnachtsinsel',
  'countries.CY': 'Zypern',
  'countries.CZ': 'Tschechische Republik',
  'countries.DE': 'Deutschland',
  'countries.DJ': 'Dschibuti',
  'countries.DK': 'Dänemark',
  'countries.DM': 'Dominica',
  'countries.DO': 'Dominikanische Republik',
  'countries.DZ': 'Algerien',
  'countries.EC': 'Ecuador',
  'countries.EE': 'Estland',
  'countries.EG': 'Ägypten',
  'countries.EH': 'Westsahara',
  'countries.ER': 'Eritrea',
  'countries.ES': 'Spanien',
  'countries.ET': 'Äthiopien',
  'countries.EU': 'Europa',
  'countries.FI': 'Finnland',
  'countries.FJ': 'Fidschi',
  'countries.FK': 'Falklandinseln (Malwinen)',
  'countries.FM': 'Mikronesien',
  'countries.FO': 'Färöer Inseln',
  'countries.FR': 'Frankreich',
  'countries.GA': 'Gabun',
  'countries.GB': 'Vereinigtes Königreich',
  'countries.GD': 'Grenada',
  'countries.GE': 'Georgien',
  'countries.GF': 'Französisch-Guyana',
  'countries.GG': 'Guernsey',
  'countries.GH': 'Ghana',
  'countries.GI': 'Gibraltar',
  'countries.GL': 'Grönland',
  'countries.GM': 'Gambia',
  'countries.GN': 'Guinea',
  'countries.GP': 'Guadeloupe',
  'countries.GQ': 'Äquatorialguinea',
  'countries.GR': 'Griechenland',
  'countries.GS': 'Südgeorgien und die Südlichen Sandwichinseln',
  'countries.GT': 'Guatemala',
  'countries.GU': 'Guam',
  'countries.GW': 'Guinea-Bissau',
  'countries.GY': 'Guyana',
  'countries.HK': 'Hongkong',
  'countries.HM': 'Heard-Insel und McDonald-Inseln',
  'countries.HN': 'Honduras',
  'countries.HR': 'Kroatien',
  'countries.HT': 'Haiti',
  'countries.HU': 'Ungarn',
  'countries.ID': 'Indonesien',
  'countries.IE': 'Irland',
  'countries.IL': 'Israel',
  'countries.IM': 'Isle of Man',
  'countries.IN': 'Indien',
  'countries.IO': 'Britisches Territorium im Indischen Ozean',
  'countries.IQ': 'Irak',
  'countries.IR': 'Iran',
  'countries.IS': 'Island',
  'countries.IT': 'Italien',
  'countries.JE': 'Jersey',
  'countries.JM': 'Jamaika',
  'countries.JO': 'Jordanien',
  'countries.JP': 'Japan',
  'countries.KE': 'Kenia',
  'countries.KG': 'Kirgisistan',
  'countries.KH': 'Kambodscha',
  'countries.KI': 'Kiribati',
  'countries.KM': 'Komoren',
  'countries.KN': 'St. Kitts und Nevis',
  'countries.KP': 'Korea',
  'countries.KR': 'Korea',
  'countries.KW': 'Kuwait',
  'countries.KY': 'Kaimaninseln',
  'countries.KZ': 'Kasachstan',
  'countries.LA': 'Demokratische Volksrepublik Laos',
  'countries.LB': 'Libanon',
  'countries.LC': 'St. Lucia',
  'countries.LI': 'Liechtenstein',
  'countries.LK': 'Sri Lanka',
  'countries.LR': 'Liberia',
  'countries.LS': 'Lesotho',
  'countries.LT': 'Litauen',
  'countries.LU': 'Luxemburg',
  'countries.LV': 'Lettland',
  'countries.LY': 'Libysch-Arabische Dschamahirija',
  'countries.MA': 'Marokko',
  'countries.MC': 'Monaco',
  'countries.MD': 'Moldawien',
  'countries.ME': 'Montenegro',
  'countries.MF': 'St. Martin',
  'countries.MG': 'Madagaskar',
  'countries.MH': 'Marshallinseln',
  'countries.MK': 'Mazedonien',
  'countries.ML': 'Mali',
  'countries.MM': 'Myanmar',
  'countries.MN': 'Mongolei',
  'countries.MO': 'Macao',
  'countries.MP': 'Nördliche Marianen',
  'countries.MQ': 'Martinique',
  'countries.MR': 'Mauretanien',
  'countries.MS': 'Montserrat',
  'countries.MT': 'Malta',
  'countries.MU': 'Mauritius',
  'countries.MV': 'Malediven',
  'countries.MW': 'Malawi',
  'countries.MX': 'Mexiko',
  'countries.MY': 'Malaysia',
  'countries.MZ': 'Mosambik',
  'countries.NA': 'Namibia',
  'countries.NC': 'Neukaledonien',
  'countries.NE': 'Niger',
  'countries.NF': 'Norfolkinsel',
  'countries.NG': 'Nigeria',
  'countries.NI': 'Nicaragua',
  'countries.NL': 'Niederlande',
  'countries.NO': 'Norwegen',
  'countries.NP': 'Nepal',
  'countries.NR': 'Nauru',
  'countries.NU': 'Niue',
  'countries.NZ': 'Neuseeland',
  'countries.O1': 'Anderes Land',
  'countries.OM': 'Oman',
  'countries.PA': 'Panama',
  'countries.PE': 'Peru',
  'countries.PF': 'Französisch-Polynesien',
  'countries.PG': 'Papua-Neuguinea',
  'countries.PH': 'Philippinen',
  'countries.PK': 'Pakistan',
  'countries.PL': 'Polen',
  'countries.PM': 'St. Pierre und Miquelon',
  'countries.PN': 'Pitcairn',
  'countries.PR': 'Puerto Rico',
  'countries.PS': 'Palästinensisches Autonomiegebiet',
  'countries.PT': 'Portugal',
  'countries.PW': 'Palau',
  'countries.PY': 'Paraguay',
  'countries.QA': 'Katar',
  'countries.RE': 'Réunion',
  'countries.RO': 'Rumänien',
  'countries.RS': 'Serbien',
  'countries.RU': 'Russische Föderation',
  'countries.RW': 'Ruanda',
  'countries.SA': 'Saudi-Arabien',
  'countries.SB': 'Salomoninseln',
  'countries.SC': 'Seychellen',
  'countries.SD': 'Sudan',
  'countries.SE': 'Schweden',
  'countries.SG': 'Singapur',
  'countries.SH': 'Sankt Helena',
  'countries.SI': 'Slowenien',
  'countries.SJ': 'Svalbard und Jan Mayen',
  'countries.SK': 'Slowakei',
  'countries.SL': 'Sierra Leone',
  'countries.SM': 'San Marino',
  'countries.SN': 'Senegal',
  'countries.SO': 'Somalia',
  'countries.SR': 'Surinam',
  'countries.ST': 'São Tomé und Príncipe',
  'countries.SV': 'El Salvador',
  'countries.SY': 'Arabische Republik Syrien',
  'countries.SZ': 'Swasiland',
  'countries.TC': 'Turks- und Caicosinseln',
  'countries.TD': 'Tschad',
  'countries.TF': 'Französische Süd-Territorien',
  'countries.TG': 'Togo',
  'countries.TH': 'Thailand',
  'countries.TJ': 'Tadschikistan',
  'countries.TK': 'Tokelau',
  'countries.TL': 'Timor-Leste',
  'countries.TM': 'Turkmenistan',
  'countries.TN': 'Tunesien',
  'countries.TO': 'Tonga',
  'countries.TR': 'Türkei',
  'countries.TT': 'Trinidad und Tobago',
  'countries.TV': 'Tuvalu',
  'countries.TW': 'Taiwan',
  'countries.TZ': 'Tansania',
  'countries.UA': 'Ukraine',
  'countries.UG': 'Uganda',
  'countries.UM': 'United States Minor Outlying Islands',
  'countries.US': 'Vereinigte Staaten',
  'countries.UY': 'Uruguay',
  'countries.UZ': 'Usbekistan',
  'countries.VA': 'Heiliger Stuhl (Staat Vatikanstadt)',
  'countries.VC': 'St. Vincent und die Grenadinen',
  'countries.VE': 'Venezuela',
  'countries.VG': 'Jungferninseln',
  'countries.VI': 'Jungferninseln',
  'countries.VN': 'Vietnam',
  'countries.VU': 'Vanuatu',
  'countries.WF': 'Wallis und Futuna',
  'countries.WS': 'Samoa',
  'countries.YE': 'Jemen',
  'countries.YT': 'Mayotte',
  'countries.ZA': 'Südafrika',
  'countries.ZM': 'Sambia',
  'countries.ZW': 'Simbabwe',
  'nationalities.AF': 'Afghanisch',
  'nationalities.AX': 'Aland-Insel',
  'nationalities.AL': 'Albanisch',
  'nationalities.DZ': 'Algerisch',
  'nationalities.AS': 'Amerikanisch-Samoanisch',
  'nationalities.AD': 'Andorranisch',
  'nationalities.AO': 'Angolanisch',
  'nationalities.AI': 'Anguillanisch',
  'nationalities.AQ': 'Antarktisch',
  'nationalities.AG': 'Antiguanisch',
  'nationalities.AR': 'Argentinisch',
  'nationalities.AM': 'Armenisch',
  'nationalities.AW': 'Arubanisch',
  'nationalities.AU': 'Australisch',
  'nationalities.AT': 'Österreichisch',
  'nationalities.AZ': 'Aserbaidschanisch',
  'nationalities.BS': 'Bahamisch',
  'nationalities.BH': 'Bahrainisch',
  'nationalities.BD': 'Bangladeschisch',
  'nationalities.BB': 'Barbadisch',
  'nationalities.BY': 'Belarussisch',
  'nationalities.BE': 'Belgisch',
  'nationalities.BZ': 'Belizeanisch',
  'nationalities.BJ': 'Beninisch',
  'nationalities.BM': 'Bermudisch',
  'nationalities.BT': 'Bhutanisch',
  'nationalities.BO': 'Bolivianisch',
  'nationalities.BA': 'Bosnisch',
  'nationalities.BW': 'Botsuanisch',
  'nationalities.BV': 'Von der Bouvetinsel',
  'nationalities.BR': 'Brasilianisch',
  'nationalities.IO': 'BIOTisch',
  'nationalities.BN': 'Bruneiisch',
  'nationalities.BG': 'Bulgarisch',
  'nationalities.BF': 'Burkinisch',
  'nationalities.BI': 'Burundisch',
  'nationalities.KH': 'Kambodschanisch',
  'nationalities.CM': 'Kamerunisch',
  'nationalities.CA': 'Kanadisch',
  'nationalities.CV': 'Kapverdisch',
  'nationalities.KY': 'Von den Kaimaninseln',
  'nationalities.CF': 'Zentralafrikanisch',
  'nationalities.TD': 'Tschadisch',
  'nationalities.CL': 'Chilenisch',
  'nationalities.CN': 'Chinesisch',
  'nationalities.CX': 'Von der Weihnachtsinsel',
  'nationalities.CC': 'Von der Kokos-Insel',
  'nationalities.CO': 'Kolumbianisch',
  'nationalities.KM': 'Komorisch',
  'nationalities.CD': 'Kongolesisch',
  'nationalities.CG': 'Kongolesisch',
  'nationalities.CK': 'Von den Cookinseln',
  'nationalities.CR': 'Costa-ricanisch',
  'nationalities.CI': 'Ivorisch',
  'nationalities.HR': 'Kroatisch',
  'nationalities.CU': 'Kubanisch',
  'nationalities.CY': 'Zypriotisch',
  'nationalities.CZ': 'Tschechisch',
  'nationalities.DK': 'Dänisch',
  'nationalities.DJ': 'Dschibutisch',
  'nationalities.DM': 'Dominikanisch',
  'nationalities.DO': 'Dominikanisch',
  'nationalities.EC': 'Ecuadorianisch',
  'nationalities.EG': 'Ägyptisch',
  'nationalities.SV': 'Salvadorianisch',
  'nationalities.GQ': 'Äquatorialguineisch',
  'nationalities.ER': 'Eritreisch',
  'nationalities.EE': 'Estnisch',
  'nationalities.ET': 'Äthiopisch',
  'nationalities.FK': 'Von den Falklandinseln',
  'nationalities.FO': 'Färöisch',
  'nationalities.FJ': 'Fidschianisch',
  'nationalities.FI': 'Finnisch',
  'nationalities.FR': 'Französisch',
  'nationalities.GF': 'Französisch-Guyanisch',
  'nationalities.PF': 'Französisch-Polynesisch',
  'nationalities.TF': 'Von den Französischen Süd-Territorien',
  'nationalities.GA': 'Gabunisch',
  'nationalities.GM': 'Gambisch',
  'nationalities.GE': 'Georgisch',
  'nationalities.DE': 'Deutsch',
  'nationalities.GH': 'Ghanaisch',
  'nationalities.GI': 'Gibraltar',
  'nationalities.GR': 'Griechisch',
  'nationalities.GL': 'Grönländisch',
  'nationalities.GD': 'Grenadisch',
  'nationalities.GP': 'Aus Guadeloupe',
  'nationalities.GU': 'Guamisch',
  'nationalities.GT': 'Guatemaltekisch',
  'nationalities.GG': 'Sarnisch',
  'nationalities.GN': 'Guineisch',
  'nationalities.GW': 'Guinea-bissauisch',
  'nationalities.GY': 'Guyanisch',
  'nationalities.HT': 'Haitianisch',
  'nationalities.HM': 'Von der Insel Heard',
  'nationalities.VA': 'Vatikanisch',
  'nationalities.HN': 'Honduranisch',
  'nationalities.HK': 'Aus Hongkong',
  'nationalities.HU': 'Ungarisch',
  'nationalities.IS': 'Isländisch',
  'nationalities.IN': 'Indisch',
  'nationalities.ID': 'Indonesisch',
  'nationalities.IR': 'Iranisch',
  'nationalities.IQ': 'Irakisch',
  'nationalities.IE': 'Irisch',
  'nationalities.IM': 'Von der Isle of Man',
  'nationalities.IL': 'Israelisch',
  'nationalities.IT': 'Italienisch',
  'nationalities.JM': 'Jamaikanisch',
  'nationalities.JP': 'Japanisch',
  'nationalities.JE': 'Jersiaisch',
  'nationalities.JO': 'Jordanisch',
  'nationalities.KZ': 'Kasachisch',
  'nationalities.KE': 'Kenianisch',
  'nationalities.KI': 'I-Kiribatisch',
  'nationalities.KW': 'Kuwaitisch',
  'nationalities.KG': 'Kirgisisch',
  'nationalities.LA': 'Laotisch',
  'nationalities.LV': 'Lettisch',
  'nationalities.LB': 'Libanesisch',
  'nationalities.LS': 'Basotho',
  'nationalities.LR': 'Liberianisch',
  'nationalities.LY': 'Libysch',
  'nationalities.LI': 'Liechtensteinisch',
  'nationalities.LT': 'Litauisch',
  'nationalities.LU': 'Luxemburgisch',
  'nationalities.MO': 'Macanesisch',
  'nationalities.MK': 'Mazedonisch',
  'nationalities.MG': 'Madagassisch',
  'nationalities.MW': 'Malawisch',
  'nationalities.MY': 'Malaiisch',
  'nationalities.MV': 'Maledivisch',
  'nationalities.ML': 'Malisch',
  'nationalities.MT': 'Maltesisch',
  'nationalities.MH': 'Marshallisch',
  'nationalities.MQ': 'Martinikanisch',
  'nationalities.MR': 'Mauretanisch',
  'nationalities.MU': 'Mauritisch',
  'nationalities.YT': 'Mahoranisch',
  'nationalities.MX': 'Mexikanisch',
  'nationalities.FM': 'Mikronesisch',
  'nationalities.MD': 'Moldawisch',
  'nationalities.MC': 'Monegassisch',
  'nationalities.MN': 'Mongolesisch',
  'nationalities.ME': 'Montenegrinisch',
  'nationalities.MF': 'St. Martinisch',
  'nationalities.MS': 'Montserratisch',
  'nationalities.MA': 'Marokkanisch',
  'nationalities.MZ': 'Mosambikanisch',
  'nationalities.MM': 'Myanmarisch',
  'nationalities.NA': 'Namibische/r',
  'nationalities.NR': 'Nauruisch',
  'nationalities.NP': 'Nepalesisch',
  'nationalities.NL': 'Niederländisch',
  'nationalities.AN': 'Niederländisch-Antillianisch',
  'nationalities.NC': 'Neukaledonisch',
  'nationalities.NZ': 'Neuseeländisch',
  'nationalities.NI': 'Nicaraguanisch',
  'nationalities.NE': 'Nigerianisch',
  'nationalities.NG': 'Nigerianisch',
  'nationalities.NU': 'Niueanisch',
  'nationalities.NF': 'Von der Norfolkinsel',
  'nationalities.KP': 'Nordkoreanisch',
  'nationalities.MP': 'Nordmarianisch',
  'nationalities.NO': 'Norwegisch',
  'nationalities.OM': 'Omanisch',
  'nationalities.PK': 'Pakistanisch',
  'nationalities.PW': 'Palauisch',
  'nationalities.PS': 'Palästinensisch',
  'nationalities.PA': 'Panamaisch',
  'nationalities.PG': 'Papua',
  'nationalities.PY': 'Paraguayisch',
  'nationalities.PE': 'Peruanisch',
  'nationalities.PH': 'Philippinisch',
  'nationalities.PN': 'Von den Pitcairninseln',
  'nationalities.PL': 'Polnisch',
  'nationalities.PT': 'Portugiesisch',
  'nationalities.PR': 'Puerto-ricanisch',
  'nationalities.QA': 'Katarisch',
  'nationalities.RE': 'Reunionesisch',
  'nationalities.RO': 'Rumänisch',
  'nationalities.RU': 'Russisch',
  'nationalities.RW': 'Ruandisch',
  'nationalities.SH': 'Von St. Helena',
  'nationalities.KN': 'Kittitianisch',
  'nationalities.LC': 'Lucianisch',
  'nationalities.PM': 'Aus Saint-Pierre',
  'nationalities.VC': 'Vincentisch',
  'nationalities.WS': 'Samoanisch',
  'nationalities.SM': 'Sammarinesisch',
  'nationalities.ST': 'São-toméisch',
  'nationalities.SA': 'Saudisch',
  'nationalities.SN': 'Senegalesisch',
  'nationalities.RS': 'Serbisch',
  'nationalities.SC': 'Seychellisch',
  'nationalities.SL': 'Sierra-leonisch',
  'nationalities.SG': 'Singapurisch',
  'nationalities.SK': 'Slowakisch',
  'nationalities.SI': 'Slowenisch',
  'nationalities.SB': 'Salomonisch',
  'nationalities.SO': 'Somalisch',
  'nationalities.ZA': 'Südafrikanisch',
  'nationalities.GS': 'Aus Südgeorgien und den Südlichen Sandwichinseln',
  'nationalities.KR': 'Südkoreanisch',
  'nationalities.ES': 'Spanisch',
  'nationalities.LK': 'Srilankisch',
  'nationalities.SD': 'Sudanesisch',
  'nationalities.SR': 'Surinamisch',
  'nationalities.SJ': 'Svalbardisch',
  'nationalities.SZ': 'Eswatinisch',
  'nationalities.SE': 'Schwedisch',
  'nationalities.CH': 'Schweizerisch',
  'nationalities.SY': 'Syrisch',
  'nationalities.TW': 'Taiwanesisch',
  'nationalities.TJ': 'Tadschikistanisch',
  'nationalities.TZ': 'Tansanisch',
  'nationalities.TH': 'Thailändisch',
  'nationalities.TL': 'Timoresisch',
  'nationalities.TG': 'Togolesisch',
  'nationalities.TK': 'Tokelauisch',
  'nationalities.TO': 'Tonganisch',
  'nationalities.TT': 'Aus Trinidad',
  'nationalities.TN': 'Tunesisch',
  'nationalities.TR': 'Türkisch',
  'nationalities.TM': 'Turkmenisch',
  'nationalities.TC': 'Von den Turks- und Caicosinseln',
  'nationalities.TV': 'Tuvaluisch',
  'nationalities.UG': 'Ugandisch',
  'nationalities.UA': 'Ukrainisch',
  'nationalities.AE': 'Emiratisch',
  'nationalities.GB': 'Britisch',
  'nationalities.US': 'Amerikanisch',
  'nationalities.UM': 'Von den United States Minor Outlying Islands',
  'nationalities.UY': 'Uruguayisch',
  'nationalities.UZ': 'Usbekisch',
  'nationalities.VU': 'Vanuatuisch',
  'nationalities.VE': 'Venezolanisch',
  'nationalities.VN': 'Vietnamesisch',
  'nationalities.VG': 'Von den Britischen Jungferninseln',
  'nationalities.VI': 'Von den Amerikanischen Jungferninsel',
  'nationalities.WF': 'Wallisisch',
  'nationalities.EH': 'Sahrawisch',
  'nationalities.YE': 'Jemenitisch',
  'nationalities.ZM': 'Sambisch',
  'nationalities.ZW': 'Simbabwisch',
  'month.1': 'Januar',
  'month.2': 'Februar',
  'month.3': 'März',
  'month.4': 'April',
  'month.5': 'Mai',
  'month.6': 'Juni',
  'month.7': 'Juli',
  'month.8': 'August',
  'month.9': 'September',
  'month.10': 'Oktober',
  'month.11': 'November',
  'month.12': 'Dezember',
  'filters.art.price.0.label': '0',
  'filters.art.price.0.helper': '0',
  'filters.art.price.100.label': ' ',
  'filters.art.price.100.helper': '100',
  'filters.art.price.200.label': '200',
  'filters.art.price.200.helper': '200',
  'filters.art.price.300.label': ' ',
  'filters.art.price.300.helper': '300',
  'filters.art.price.500.label': '500',
  'filters.art.price.500.helper': '500',
  'filters.art.price.1000.label': ' ',
  'filters.art.price.1000.helper': '1000',
  'filters.art.price.2000.label': '2000',
  'filters.art.price.2000.helper': '2000',
  'filters.art.price.5000.label': ' ',
  'filters.art.price.5000.helper': '5000',
  'filters.art.price.10000.label': ' ',
  'filters.art.price.10000.helper': '10000',
  'filters.art.price.UP.label': 'Max.',
  'filters.art.price.UP.helper': 'Maximum',
  'filters.art.color.colourful': 'Farbenfroh',
  'filters.art.color.black-white': 'Schwarz-Weiß',
  'filters.art.color.white': 'Weiß',
  'filters.art.color.grey': 'Grau',
  'filters.art.color.black': 'Schwarz',
  'filters.art.color.maroon-neutral': 'Kastanienbraun Neutral',
  'filters.art.color.orange': 'Orange',
  'filters.art.color.yellow': 'Gelb',
  'filters.art.color.green': 'Grün',
  'filters.art.color.blue': 'Blau',
  'filters.art.color.purple': 'Lila',
  'filters.art.color.pink': 'Rosa',
  'filters.art.color.red': 'Rot',
  'filters.art.medium.collage': 'Collage',
  'filters.art.medium.digital': 'Digital',
  'filters.art.medium.drawings': 'Zeichnungen',
  'filters.art.medium.installation': 'Installation',
  'filters.art.medium.paintings': 'Gemälde',
  'filters.art.medium.photography': 'Fotografie',
  'filters.art.medium.prints': 'Kunstdrucke',
  'filters.art.medium.sculpture': 'Skulpturen',
  'filters.art.subMedium.photo': 'Foto',
  'filters.art.subMedium.mixed-media-2': 'Mixed Media',
  'filters.art.subMedium.oil': 'Öl',
  'filters.art.subMedium.digital': 'Digital',
  'filters.art.subMedium.screenprint': 'Siebdruck',
  'filters.art.subMedium.transfer': 'Transferdruck',
  'filters.art.subMedium.fabric': 'Stoff',
  'filters.art.subMedium.fabric-1': 'Stoff',
  'filters.art.subMedium.mixed-media-1': 'Mixed Media',
  'filters.art.subMedium.paper-1': 'Papier',
  'filters.art.subMedium.chalk': 'Kreide',
  'filters.art.subMedium.charcoal': 'Holzkohle',
  'filters.art.subMedium.ink': 'Tinte',
  'filters.art.subMedium.ink-1': 'Tinte',
  'filters.art.subMedium.pastel': 'Pastell',
  'filters.art.subMedium.pencil': 'Bleistift',
  'filters.art.subMedium.acrylic': 'Acryl',
  'filters.art.subMedium.airbrush': 'Airbrush',
  'filters.art.subMedium.gouache': 'Gouache',
  'filters.art.subMedium.tempera': 'Tempera',
  'filters.art.subMedium.watercolour': 'Aquarell',
  'filters.art.subMedium.black-white': 'Schwarz-Weiß',
  'filters.art.subMedium.crayon': 'Buntstift',
  'filters.art.subMedium.colour': 'Farbe',
  'filters.art.subMedium.digital-1': 'Digital',
  'filters.art.subMedium.mixed-media': 'Mixed Media',
  'filters.art.subMedium.encaustic': 'Enkaustik',
  'filters.art.subMedium.etching': 'Radierung',
  'filters.art.subMedium.lino': 'Lino',
  'filters.art.subMedium.lithograph': 'Lithografie',
  'filters.art.subMedium.marble': 'Marmor',
  'filters.art.subMedium.mixed-media-3': 'Mixed Media',
  'filters.art.subMedium.mono': 'Mono',
  'filters.art.subMedium.woodcut': 'Holzschnitt',
  'filters.art.subMedium.bronze': 'Bronze',
  'filters.art.subMedium.ceramic': 'Keramik',
  'filters.art.subMedium.clay': 'Ton',
  'filters.art.subMedium.enamel': 'Emaille',
  'filters.art.subMedium.glass': 'Glas',
  'filters.art.subMedium.kinetic': 'Kinetisch',
  'filters.art.subMedium.metal': 'Metall',
  'filters.art.subMedium.paper': 'Papier',
  'filters.art.subMedium.pinhole': 'Lochkamera',
  'filters.art.subMedium.plastic': 'Kunststoff',
  'filters.art.subMedium.resin': 'Harz',
  'filters.art.subMedium.vector': 'Vektor',
  'filters.art.subMedium.wood': 'Holz',
  'filters.art.subMedium.stone': 'Stein',
  'filters.art.stage.new': 'Neues & Bemerkenswertes',
  'filters.art.stage.emerging': 'Nachwuchskünstler',
  'filters.art.stage.established': 'Etablierte Künstler',
  'filters.art.orientation.L': 'Landschaft',
  'filters.art.orientation.P': 'Portrait',
  'filters.art.orientation.S': 'Rechteckig',
  'filters.art.size.S.label': 'S',
  'filters.art.size.S.helper': 'Die längste Seite ist bis zu {value} lang',
  'filters.art.size.M.label': 'M',
  'filters.art.size.M.helper': 'Die längste Seite ist bis zu {value} lang',
  'filters.art.size.L.label': 'L',
  'filters.art.size.L.helper': 'Die längste Seite ist bis zu {value} lang',
  'filters.art.size.XL.label': 'XL',
  'filters.art.size.XL.helper': 'Die längste Seite ist bis zu {value} lang',
  'filters.art.size.XXL.label': 'XXL',
  'filters.art.size.XXL.helper': 'Die längste Seite ist über {value} lang',
  'filters.art.style.abstract': 'Abstrakt',
  'filters.art.style.abstract-expressionism': 'Abstrakter Expressionismus',
  'filters.art.style.abstract-marble': 'Marmor',
  'filters.art.style.art-deco': 'Art déco',
  'filters.art.style.cartoon': 'Cartoon',
  'filters.art.style.conceptual': 'Konzeptuell',
  'filters.art.style.colour-field': 'Farbfeld',
  'filters.art.style.cubism': 'Kubismus',
  'filters.art.style.geometric-abstract': 'Geometrisch abstrakt',
  'filters.art.style.dada': 'Dadaismus',
  'filters.art.style.expressionistic': 'Expressionistisch',
  'filters.art.style.graphic': 'Grafisch',
  'filters.art.style.minimalistic': 'Minimalistisch',
  'filters.art.style.mosaic': 'Mosaik',
  'filters.art.style.figurative': 'Figurativ',
  'filters.art.style.geometric': 'Geometrisch',
  'filters.art.style.illustrative': 'Illustrativ',
  'filters.art.style.impressionistic': 'Impressionistisch',
  'filters.art.style.photorealism': 'Fotorealismus',
  'filters.art.style.pop-art': 'Pop Art',
  'filters.art.style.realistic': 'Realistisch',
  'filters.art.style.stencil': 'Schablone',
  'filters.art.style.street-art': 'Street Art',
  'filters.art.style.surrealist': 'Surrealistisch',
  'filters.art.style.other': 'Andere',
  'filters.art.subject.architecture': 'Architektur',
  'filters.art.subject.botanical': 'Botanisch',
  'filters.art.subject.celebrities': 'Prominente',
  'filters.art.subject.documentary': 'Dokumentarisch/Dokumentarfilm',
  'filters.art.subject.other': 'Andere',
  'filters.art.subject.science-technology': 'Wissenschaft und Technologie',
  'filters.art.subject.seascapes': 'Meereslandschaften',
  'filters.art.subject.transport-auto': 'Transportwesen und Autos',
  'filters.art.subject.typography': 'Typografie',
  'filters.art.subject.urban': 'Urban',
  'filters.art.subject.maps': 'Karten',
  'filters.art.subject.fantasy': 'Fantasy',
  'filters.art.subject.food': 'Lebensmittel',
  'filters.art.subject.abstract': 'Abstrakt',
  'filters.art.subject.animals': 'Tiere',
  'filters.art.subject.cityscapes': 'Stadtansichten',
  'filters.art.subject.fashion': 'Mode',
  'filters.art.subject.landscapes': 'Landschaften',
  'filters.art.subject.music': 'Musik',
  'filters.art.subject.nature': 'Natur',
  'filters.art.subject.nudes': 'Akte',
  'filters.art.subject.politics': 'Politik',
  'filters.art.subject.portraits': 'Portraits',
  'filters.art.subject.spirituality': 'Spiritualität',
  'filters.art.subject.sports': 'Sport',
  'filters.art.subject.still-life': 'Stillleben',
  'filters.art.subject.underwater': 'Unterwasser',
  'filters.art.subject.wildlife': 'Wildtiere',
  'filters.art.subSubject.bird': 'Vögel',
  'filters.art.subSubject.cat': 'Katzen',
  'filters.art.subSubject.cow': 'Kühe',
  'filters.art.subSubject.dog': 'Hunde',
  'filters.art.subSubject.fish': 'Fische',
  'filters.art.subSubject.horse': 'Pferde',
  'filters.art.subSubject.hong-kong': 'Hongkong',
  'filters.art.subSubject.london-1': 'London',
  'filters.art.subSubject.new-york-1': 'New York',
  'filters.art.subSubject.paris': 'Paris',
  'filters.art.subSubject.singapore': 'Singapur',
  'filters.art.subSubject.beach': 'Strand',
  'filters.art.subSubject.rural': 'Ländlich',
  'filters.art.subSubject.sky': 'Himmel',
  'filters.art.subSubject.london': 'London',
  'filters.art.subSubject.new-york': 'New York',
  'filters.art.subSubject.flower': 'Blumen',
  'filters.art.subSubject.tree': 'Bäume',
  'filters.art.subSubject.leaves': 'Blätter',
  'filters.art.subSubject.female': 'Weiblich',
  'filters.art.subSubject.male': 'Männlich',
  'filters.art.subSubject.religion': 'Religion',
  'filters.art.subSubject.athletics': 'Athletik',
  'filters.art.subSubject.cycling': 'Radfahren',
  'filters.art.subSubject.football': 'Fußball',
  'filters.art.subSubject.flower-1': 'Blumen',
  'filters.art.subSubject.food': 'Lebensmittel',
  'filters.art.subSubject.airplane': 'Flugzeuge',
  'filters.art.subSubject.bike': 'Fahrräder',
  'filters.art.subSubject.car': 'Autos',
  'filters.art.subSubject.ship': 'Schiffe',
  'filters.art.subSubject.train': 'Züge',
  'filters.art.subSubject.yacht': 'Yacht',
  'filters.art.width.UP.label': 'Max',
  'filters.art.width.UP.helper': 'Maximum',
  'filters.art.height.UP.label': 'Max',
  'filters.art.height.UP.helper': 'Maximum',
  'filters.art.breadcrumbs.medium.collage': 'Collage',
  'filters.art.breadcrumbs.medium.digital': 'Digital',
  'filters.art.breadcrumbs.medium.drawings': 'Zeichnungen',
  'filters.art.breadcrumbs.medium.installation': 'Installation',
  'filters.art.breadcrumbs.medium.paintings': 'Gemälde',
  'filters.art.breadcrumbs.medium.photography': 'Fotografie',
  'filters.art.breadcrumbs.medium.prints': 'Kunstdrucke',
  'filters.art.breadcrumbs.medium.sculpture': 'Skulpturen',
  'filters.art.breadcrumbs.style.abstract': 'Abstrakt',
  'filters.art.breadcrumbs.style.abstract-expressionism': 'Abstrakter Expressionismus',
  'filters.art.breadcrumbs.style.abstract-marble': 'Marmor',
  'filters.art.breadcrumbs.style.art-deco': 'Art déco',
  'filters.art.breadcrumbs.style.cartoon': 'Cartoon',
  'filters.art.breadcrumbs.style.conceptual': 'Konzeptuell',
  'filters.art.breadcrumbs.style.colour-field': 'Farbfeld',
  'filters.art.breadcrumbs.style.cubism': 'Kubismus',
  'filters.art.breadcrumbs.style.geometric-abstract': 'Geometrisch abstrakt',
  'filters.art.breadcrumbs.style.dada': 'Dadaismus',
  'filters.art.breadcrumbs.style.expressionistic': 'Expressionistisch',
  'filters.art.breadcrumbs.style.graphic': 'Grafisch',
  'filters.art.breadcrumbs.style.minimalistic': 'Minimalistisch',
  'filters.art.breadcrumbs.style.mosaic': 'Mosaik',
  'filters.art.breadcrumbs.style.figurative': 'Figurativ',
  'filters.art.breadcrumbs.style.geometric': 'Geometrisch',
  'filters.art.breadcrumbs.style.illustrative': 'Illustrativ',
  'filters.art.breadcrumbs.style.impressionistic': 'Impressionistisch',
  'filters.art.breadcrumbs.style.photorealism': 'Fotorealismus',
  'filters.art.breadcrumbs.style.pop-art': 'Pop Art',
  'filters.art.breadcrumbs.style.realistic': 'Realistisch',
  'filters.art.breadcrumbs.style.stencil': 'Schablone',
  'filters.art.breadcrumbs.style.street-art': 'Street Art',
  'filters.art.breadcrumbs.style.surrealist': 'Surrealistisch',
  'filters.art.breadcrumbs.style.other': 'Andere',
  'filters.collection.medium.collage': 'Collage',
  'filters.collection.medium.drawings': 'Zeichnungen',
  'filters.collection.medium.installation': 'Installation',
  'filters.collection.medium.paintings': 'Gemälde',
  'filters.collection.medium.photography': 'Fotografie',
  'filters.collection.medium.prints': 'Kunstdrucke',
  'filters.collection.medium.sculpture': 'Skulpturen',
  'filters.collection.style.abstract': 'Abstrakt',
  'filters.collection.style.art-deco': 'Art déco',
  'filters.collection.style.cartoon': 'Cartoon',
  'filters.collection.style.conceptual': 'Konzeptuell',
  'filters.collection.style.cubism': 'Kubismus',
  'filters.collection.style.dada': 'Dadaismus',
  'filters.collection.style.expressionistic': 'Expressionistisch',
  'filters.collection.style.minimalistic': 'Minimalistisch',
  'filters.collection.style.figurative': 'Figurativ',
  'filters.collection.style.geometric': 'Geometrisch',
  'filters.collection.style.illustrative': 'Illustrativ',
  'filters.collection.style.impressionistic': 'Impressionistisch',
  'filters.collection.style.pop-art': 'Pop Art',
  'filters.collection.style.realistic': 'Realistisch',
  'filters.collection.style.street-art': 'Street Art',
  'filters.collection.style.surrealist': 'Surrealistisch',
  'filters.collection.subject.architecture': 'Architektur',
  'filters.collection.subject.botanical': 'Botanisch',
  'filters.collection.subject.celebrities': 'Prominente',
  'filters.collection.subject.documentary': 'Dokumentarisch/Dokumentarfilm',
  'filters.collection.subject.science-technology': 'Wissenschaft und Technologie',
  'filters.collection.subject.seascapes': 'Meereslandschaften',
  'filters.collection.subject.transport-auto': 'Transportwesen und Autos',
  'filters.collection.subject.typography': 'Typografie',
  'filters.collection.subject.urban': 'Urban',
  'filters.collection.subject.maps': 'Karten',
  'filters.collection.subject.food': 'Lebensmittel',
  'filters.collection.subject.animals': 'Tiere',
  'filters.collection.subject.cityscapes': 'Stadtansichten',
  'filters.collection.subject.fashion': 'Mode',
  'filters.collection.subject.landscapes': 'Landschaften',
  'filters.collection.subject.nudes': 'Akte',
  'filters.collection.subject.plants-flowers': 'Pflanzen & Blumen',
  'filters.collection.subject.portraits': 'Portraits',
  'filters.collection.subject.spirituality': 'Spiritualität',
  'filters.collection.subject.sports': 'Sport',
  'filters.collection.subject.still-life': 'Stillleben',
  'filters.collection.breadcrumbs.medium.collage': 'Collage',
  'filters.collection.breadcrumbs.medium.drawings': 'Zeichnungen',
  'filters.collection.breadcrumbs.medium.installation': 'Installation',
  'filters.collection.breadcrumbs.medium.paintings': 'Gemälde',
  'filters.collection.breadcrumbs.medium.photography': 'Fotografie',
  'filters.collection.breadcrumbs.medium.prints': 'Kunstdrucke',
  'filters.collection.breadcrumbs.medium.sculpture': 'Skulpturen',
  'filters.collection.breadcrumbs.style.abstract': 'Abstrakt',
  'filters.collection.breadcrumbs.style.art-deco': 'Art déco',
  'filters.collection.breadcrumbs.style.cartoon': 'Cartoon',
  'filters.collection.breadcrumbs.style.conceptual': 'Konzeptuell',
  'filters.collection.breadcrumbs.style.cubism': 'Kubismus',
  'filters.collection.breadcrumbs.style.dada': 'Dadaismus',
  'filters.collection.breadcrumbs.style.expressionistic': 'Expressionistisch',
  'filters.collection.breadcrumbs.style.minimalistic': 'Minimalistisch',
  'filters.collection.breadcrumbs.style.figurative': 'Figurativ',
  'filters.collection.breadcrumbs.style.geometric': 'Geometrisch',
  'filters.collection.breadcrumbs.style.illustrative': 'Illustrativ',
  'filters.collection.breadcrumbs.style.impressionistic': 'Impressionistisch',
  'filters.collection.breadcrumbs.style.pop-art': 'Pop Art',
  'filters.collection.breadcrumbs.style.realistic': 'Realistisch',
  'filters.collection.breadcrumbs.style.street-art': 'Street Art',
  'filters.collection.breadcrumbs.style.surrealist': 'Surrealistisch',
  'filters.artist.medium.collagists': 'Kollagist',
  'filters.artist.medium.digital': 'Digitale Künstler',
  'filters.artist.medium.draftspersons': 'Zeichner',
  'filters.artist.medium.installation': 'Installationskünstler',
  'filters.artist.medium.painters': 'Maler',
  'filters.artist.medium.photographers': 'Fotografen',
  'filters.artist.medium.printmakers': 'Grafiker',
  'filters.artist.medium.sculptors': 'Bildhauer',
  'filters.artist.style.abstract': 'Abstrakt',
  'filters.artist.style.abstract-expressionism': 'Abstrakter Expressionismus',
  'filters.artist.style.art-deco': 'Art déco',
  'filters.artist.style.cartoon': 'Cartoon',
  'filters.artist.style.colour-field': 'Farbfeld',
  'filters.artist.style.conceptual': 'Konzeptuell',
  'filters.artist.style.cubism': 'Kubismus',
  'filters.artist.style.dada': 'Dadaismus',
  'filters.artist.style.expressionistic': 'Expressionistisch',
  'filters.artist.style.figurative': 'Figurativ',
  'filters.artist.style.graphic': 'Grafisch',
  'filters.artist.style.geometric': 'Geometrisch',
  'filters.artist.style.geometric-abstract': 'Geometrisch abstrakt',
  'filters.artist.style.illustrative': 'Illustrativ',
  'filters.artist.style.impressionistic': 'Impressionistisch',
  'filters.artist.style.abstract-marble': 'Marmor',
  'filters.artist.style.minimalistic': 'Minimalistisch',
  'filters.artist.style.mosaic': 'Mosaik',
  'filters.artist.style.photorealism': 'Fotorealismus',
  'filters.artist.style.pop-art': 'Pop Art',
  'filters.artist.style.realistic': 'Realistisch',
  'filters.artist.style.stencil': 'Schablone',
  'filters.artist.style.street-art': 'Street Art',
  'filters.artist.style.surrealist': 'Surrealistisch',
  'filters.artist.region.african': 'Afrikanisch',
  'filters.artist.region.north-american': 'Nordamerikanisch',
  'filters.artist.region.latin-american': 'Lateinamerikanisch',
  'filters.artist.region.caribbean': 'Karibisch',
  'filters.artist.region.european': 'Europäisch',
  'filters.artist.region.scandinavian': 'Skandinavisch',
  'filters.artist.region.easter-european': 'Osteuropäisch',
  'filters.artist.region.asian': 'Asiatisch',
  'filters.artist.region.south-asian': 'Südasiatisch',
  'filters.artist.region.middle-eastern': 'Nahöstlich',
  'filters.artist.region.oceanian': 'Australisch und Ozeanisch',
  'filters.artist.gender.M': 'M',
  'filters.artist.gender.F': 'F',
  'filters.artist.stage.new': 'Neues & Bemerkenswertes',
  'filters.artist.stage.emerging': 'Nachwuchskünstler',
  'filters.artist.stage.established': 'Etablierte Künstler',
  'filters.artist.breadcrumbs.medium.collage': 'Collage',
  'filters.artist.breadcrumbs.medium.drawings': 'Zeichnungen',
  'filters.artist.breadcrumbs.medium.installation': 'Installation',
  'filters.artist.breadcrumbs.medium.paintings': 'Gemälde',
  'filters.artist.breadcrumbs.medium.photography': 'Fotografie',
  'filters.artist.breadcrumbs.medium.prints': 'Kunstdrucke',
  'filters.artist.breadcrumbs.medium.sculpture': 'Skulpturen',
  'filters.artist.breadcrumbs.style.abstract': 'Abstrakt',
  'filters.artist.breadcrumbs.style.art-deco': 'Art déco',
  'filters.artist.breadcrumbs.style.cartoon': 'Cartoon',
  'filters.artist.breadcrumbs.style.conceptual': 'Konzeptuell',
  'filters.artist.breadcrumbs.style.cubism': 'Kubismus',
  'filters.artist.breadcrumbs.style.dada': 'Dadaismus',
  'filters.artist.breadcrumbs.style.expressionistic': 'Expressionistisch',
  'filters.artist.breadcrumbs.style.minimalistic': 'Minimalistisch',
  'filters.artist.breadcrumbs.style.figurative': 'Figurativ',
  'filters.artist.breadcrumbs.style.geometric': 'Geometrisch',
  'filters.artist.breadcrumbs.style.illustrative': 'Illustrativ',
  'filters.artist.breadcrumbs.style.impressionistic': 'Impressionistisch',
  'filters.artist.breadcrumbs.style.pop-art': 'Pop Art',
  'filters.artist.breadcrumbs.style.realistic': 'Realistisch',
  'filters.artist.breadcrumbs.style.street-art': 'Street Art',
  'filters.artist.breadcrumbs.style.surrealist': 'Surrealistisch',
  'filters.artist.singular.medium.collagists': 'Collagist',
  'filters.artist.singular.medium.digital': 'Digitaler Künstler',
  'filters.artist.singular.medium.draftspersons': 'Zeichner',
  'filters.artist.singular.medium.installation': 'Installationskünstler',
  'filters.artist.singular.medium.painters': 'Maler',
  'filters.artist.singular.medium.photographers': 'Fotograf',
  'filters.artist.singular.medium.printmakers': 'Grafiker',
  'filters.artist.singular.medium.sculptors': 'Bildhauer',
  'brands.sohoHome': 'Soho Home',
  'common.yes': 'Ja',
  'common.no': 'Nein',
  'common.ok': 'OK',
  'common.or': 'oder',
  'common.findOutMore': 'Mehr erfahren ',
  'common.here': 'hier',
  'common.to': 'zu',
  'common.by': 'von',
  'common.change': 'Ändern',
  'common.edit': 'Bearbeiten',
  'common.wishlist': 'Wunschzettel',
  'common.addToWishlist': 'Zum Wunschzettel hinzufügen',
  'common.removeFromWishlist': 'Vom Wunschzettel entfernen',
  'common.yourWishlist': 'Ihr Wunschzettel',
  'common.account': 'Konto',
  'common.apply': 'Anwenden',
  'common.remove': 'Entfernen',
  'common.read_more': 'Mehr lesen',
  'common.read_less': 'Weniger lesen',
  'common.show_more': 'Mehr anzeigen',
  'common.show_less': 'Weniger anzeigen',
  'common.learnMore': 'Mehr Erfahren',
  'common.previous': 'Zurück',
  'common.next': 'Weiter',
  'common.share': 'Teilen',
  'common.follow': 'Folgen',
  'common.unfollow': 'Entfolgen',
  'common.enquire': 'anfragen',
  'common.day': 'Tag',
  'common.month': 'Monat',
  'common.year': 'Jahr',
  'common.gender.M': 'Männlich',
  'common.gender.F': 'Weiblich',
  'common.durations.month.full': 'Monat',
  'common.durations.month.abbreviated': 'mo',
  'common.birthdayAbbreviated': 'B.',
  'common.timediff.lessThanMin': 'weniger als eine Minute',
  'common.timediff.minutes': '{minutes, plural, one {vor # Minute} other {vor # Minuten}}',
  'common.timediff.hours': '{hours, plural, one {vor # Stunde} other {vor # Stunden}}',
  'common.timediff.days': '{days, plural, one {vor # Tag} other {vor # Tagen}}',
  'common.timediff.weeks': '{weeks, plural, one {vor # Woche} other {vor # Wochen}}',
  'common.timediff.months': '{months, plural, one {vor # Monat} other {vor # Monaten}}',
  'common.timediff.moreThanYear': 'vor über einem Jahr',
  'common.you': 'Sie',
  'common.objectByOwner': '{object} von {owner}',
  'common.byName': 'Von {name}',
  'common.byNameLowercase': 'von {name}',
  'common.rssFeed': 'RSS-Feed',
  'common.viewMore': 'Mehr sehen',
  'common.reviews': 'Reviews von Rise Art',
  'common.unitCm': '{value}cm',
  'common.unitIn': '{value}in',
  'common.artistName': '{name} Künstler',
  'components.activity.listTitle': 'Neueste Aktivität',
  'components.activity.trending.title': 'Sammler haben ihre Augen auf',
  'components.activity.trending.subTitle': 'Ihre Echtzeit-Aktivitätszentrale',
  'components.activity.trending.labels.product-sold': 'gerade verkauft',
  'components.activity.trending.labels.product-rental': 'gerade vermietet',
  'components.activity.trending.labels.favorite-product': 'auf Wunschzettel',
  'components.activity.trending.labels.cart-add': 'zum Warenkorb hinzugefügt',
  'components.activity.trending.labels.artist-shortlisted': 'neuer Künstler',
  'components.activity.trending.labels.follow-shortlisted': 'gefolgt von',
  'components.activity.renderers.cart-add': '{objectByOwner} wurde in den Warenkorb gelegt',
  'components.activity.renderers.cart-add-creator-me':
    '{objectByOwner} wurde in den Warenkorb gelegt',
  'components.activity.renderers.product-sold': '{objectByOwner} wurde verkauft',
  'components.activity.renderers.product-sold-creator-me': '{objectByOwner} wurde verkauft',
  'components.activity.renderers.product-rental': '{objectByOwner} wurde gemietet',
  'components.activity.renderers.product-rental-creator-me': '{objectByOwner} wurde gemietet',
  'components.activity.renderers.favorite-product':
    '{objectByOwner} wurde auf den Wunschzettel gesetzt',
  'components.activity.renderers.favorite-product-creator-me':
    '{objectByOwner} wurde auf den Wunschzettel gesetzt',
  'components.activity.renderers.follow-shortlisted': '{creatorName} folgt jetzt {object}',
  'components.activity.renderers.follow-shortlisted-creator-me': 'Sie folgen jetzt {object}',
  'components.activity.renderers.product-available':
    '{creatorName} hat {object} zum Verkauf angeboten',
  'components.activity.renderers.product-available-creator-me':
    'Sie haben {object} zum Verkauf angeboten',
  'components.activity.renderers.event-created':
    '{creatorName} hat die Veranstaltung {object} erstellt',
  'components.activity.renderers.event-created-creator-me':
    'Sie haben die Veranstaltung {object} erstellt',
  'components.activity.renderers.event-updated':
    '{creatorName} hat die Veranstaltung {object} aktualisiert',
  'components.activity.renderers.event-updated-creator-me':
    'Sie haben die Veranstaltung {object} aktualisiert',
  'components.activity.renderers.event-canceled':
    '{creatorName} hat die Veranstaltung {object} abgesagt',
  'components.activity.renderers.event-canceled-creator-me':
    'Sie haben die Veranstaltung {object} abgesagt',
  'components.activity.renderers.event-rsvp-yes': '{creatorName} nimmt jetzt teil an {object}',
  'components.activity.renderers.event-rsvp-yes-creator-me': 'Sie nehmen jetzt teil an {object}',
  'components.activity.renderers.article-created':
    '{creatorName} hat einen Artikel {object} geschrieben',
  'components.activity.renderers.article-created-creator-me':
    'Sie haben einen Artikel {object} geschrieben',
  'components.activity.renderers.collection-created':
    '{creatorName} hat eine Sammlung {object} kuratiert',
  'components.activity.renderers.collection-created-creator-me':
    'Sie haben eine Sammlung {object} kuratiert',
  'components.breadcrumbs.home': 'Online Kunst kaufen',
  'components.breadcrumbs.blog': 'Editorial',
  'components.breadcrumbs.guides': 'Leitfäden',
  'components.breadcrumbs.art': 'Alle Kunst',
  'components.breadcrumbs.artist': 'Alle Künstler',
  'components.breadcrumbs.collection': 'Alle Sammlungen',
  'components.breadcrumbs.activityList': 'Neueste Aktivität',
  'components.breadcrumbs.eventList': 'Veranstaltungen',
  'components.layout.recentlyViewed.long': 'Da weitermachen, wo Sie aufgehört haben',
  'components.layout.recentlyViewed.short': 'Kürzlich angesehen',
  'components.art.rent_for': 'Miete für',
  'components.art.sold': 'Verkauft',
  'components.art.sort': 'sortieren',
  'components.art.about_author': 'Über {name}',
  'components.art.by': 'Von',
  'components.art.includesOption': 'Enthält',
  'components.art.addOption': 'Hinzufügen',
  'components.art.size_tooltip_heading': 'Größe des Kunstwerks',
  'components.art.size_tooltip_description':
    'Physische Größe des Kunstwerks, gemessen als Höhe mal Breite mal Tiefe. Kunstwerke, die mit Rahmen gekauft werden, sind in der Regel in jeder Richtung 5 cm (2,5 Zoll) länger.',
  'components.art.artwork_by_artist': '{title} von {artist}',
  'components.art.addFrame': 'Einen Rahmen hinzufügen',
  'components.art.framed': 'Gerahmt',
  'components.art.view_full_details': 'Alle Details anzeigen',
  'components.art.add_to_basket': 'In den Warenkorb legen',
  'components.art.rent_monthly': 'Miete von {rentPrice}/Monat',
  'components.art.edition_of': 'Auflage von {editionSize}',
  'components.art.edition_of_short': 'von {editionSize}',
  'components.art.instalments.infoText': 'nur {priceInInstalments}/Monat',
  'components.art.instalments.hintTitle': 'In Raten zahlen',
  'components.art.instalments.hintDescription':
    'Bezahlen Sie Ihr Kunstwerk in monatlichen zinslosen Raten bereits ab {priceInInstalments}/Monat.',
  'components.art.instalments.hintLink': 'Mehr erfahren.',
  'components.art.options.insuranceHintTitle': 'Haftungsverzicht',
  'components.art.options.insuranceHintText':
    'Gegen eine geringe monatliche Gebühr reduzieren wir den Betrag, den Sie im Falle eines Unfallschadens oder Diebstahls zu zahlen haben.',
  'components.art.options.rentHelpTitle': 'Wie funktioniert das Mieten von Kunst?',
  'components.art.options.frame.black_frame': 'Schwarzer Rahmen',
  'components.art.options.frame.walnut_frame': 'Nussbaum Rahmen',
  'components.art.options.frame.white_frame': 'Weisser Rahmen',
  'components.art.full_details': 'Alle Details anzeigen',
  'components.art.noFrame': 'Ohne Rahmen',
  'components.art.unframed': 'Ungerahmt',
  'components.art.shipsFramed': 'Lieferung mit Rahmen',
  'components.art.shipsFramedTooltipHeading': 'Kunstwerk wird gerahmt geliefert',
  'components.art.shipsFramedTooltipDescription':
    'Dieses Kunstwerk wird gerahmt verkauft. Weitere Informationen über den Rahmen finden Sie in den Detailangaben zum Kunstwerk.',
  'components.art.shipsReadyToHang': 'Versandfertig bereit zum Aufhängen',
  'components.art.shipsReadyToHangTooltipHeading': 'Kunstwerk bereit zum Aufhängen',
  'components.art.shipsReadyToHangTooltipDescription':
    'Dieses Kunstwerk wird hängefertig verkauft. Weitere Informationen finden Sie in den Detailangaben zum Kunstwerk.',
  'components.art.shipsFramedAndHang': 'Gerahmt und hängefertig',
  'components.art.sold_out': 'Dieses Kunstwerk ist leider ausverkauft.',
  'components.art.is_rented': 'Dieses Kunstwerk ist leider vermietet.',
  'components.art.is_enquire':
    'Das Kunstwerk steht nicht zum Kauf, bitte erkundigen Sie sich nach der Verfügbarkeit.',
  'components.art.enquire_work': 'Nach diesem Kunstwerk erkundigen',
  'components.art.commissionArtist': 'Diesen Künstler beauftragen',
  'components.art.makeOffer': 'Angebot abgeben',
  'components.art.unavailable': 'Dieses Kunstwerk ist nicht verfügbar.',
  'components.art.unavailable_to_buy':
    'Wenn Sie dieses Werk erwerben möchten, nehmen Sie bitte Kontakt mit uns auf.',
  'components.art.unavailable_in_store':
    'Dieses Kunstwerk kann derzeit nicht in dieser Währung erworben werden. Sie können dieses Kunstwerk in {availableCurrencies} {totalStores, plural, one {Währung} other {Währungen}} kaufen, indem Sie oben auf der Seite Ihre Präferenzen ändern.',
  'components.art.details.type': 'Typ',
  'components.art.details.medium': 'Medium',
  'components.art.details.style': 'Stil',
  'components.art.details.subject': 'Thema',
  'components.art.details.year': 'Jahr',
  'components.art.details.editionSize': 'Edition',
  'components.art.details.size': 'Größe',
  'components.art.details.readyToHang': 'Hängefertig',
  'components.art.details.framed': 'Rahmen',
  'components.art.details.signed': 'Signiert',
  'components.art.details.materials': 'Materialien',
  'components.art.details.shipping': 'Versand',
  'components.art.details.viewInRoom': 'Galerie ansehen',
  'components.art.details.viewInRoomTruncated': 'Im Raum',
  'components.art.art_types.original_art': 'Originale Kunst',
  'components.art.art_types.limited_edition': 'Limitierte Auflage',
  'components.art.urgentMessages.original.heading': 'Original-Kunstwerk',
  'components.art.urgentMessages.original.content':
    'Nur 1 verfügbar. Holen Sie es sich, bevor es weg ist',
  'components.art.urgentMessages.edition.heading': 'Holen Sie es sich',
  'components.art.urgentMessages.edition.content': 'In begrenzter Anzahl verfügbar',
  'components.art.urgentMessages.editionLimited.heading': 'Nicht verpassen',
  'components.art.urgentMessages.editionLimited.content':
    'Von dieser Edition gibt es nur noch {stock}',
  'components.art.urgentMessages.cart.originalSingle.heading':
    'Holen Sie es sich, bevor es weg ist.',
  'components.art.urgentMessages.cart.originalSingle.content':
    'Ihr Warenkorb enthält ein Original-Kunstwerk, von dem nur noch 1 verfügbar ist.',
  'components.art.urgentMessages.cart.originalMultiple.heading':
    'Holen Sie es sich, bevor es weg ist.',
  'components.art.urgentMessages.cart.originalMultiple.content':
    'Ihr Warenkorb enthält ein Original-Kunstwerk. Davon ist jeweils nur noch 1 verfügbar.',
  'components.art.urgentMessages.cart.editionLimited.heading': 'Nicht verpassen:',
  'components.art.urgentMessages.cart.editionLimited.content':
    'Ihr Warenkorb enthält Editionen, die nur noch in sehr begrenzter Anzahl verfügbar sind.',
  'components.art.urgentMessages.cart.editionMultiple.heading': 'Nicht verpassen:',
  'components.art.urgentMessages.cart.editionMultiple.content':
    'Das Kunstwerk in Ihrem Warenkorb gehört erst dann wirklich Ihnen, wenn Sie den Kauf abgeschlossen haben.',
  'components.art.product_info': 'Details zum Kunstwerk',
  'components.art.need_to_know': 'Wissenwertes',
  'components.art.additional_info': 'Zusätzliche Informationen',
  'components.art.related_content': 'Verwandte Inhalte',
  'components.art.shipsFromCountry': 'Versand aus {country}',
  'components.art.shipsFromShort': 'Versand aus {country} bis zum {date}',
  'components.art.shipsFromLong': 'Versand aus {country} am oder vor dem {date}:',
  'components.art.curatorAvatarHeading': 'Der Insider {name} sagt:',
  'components.art.curatorHeading': 'Unser Insider meint:',
  'components.art.need_help.title': 'Benötigen Sie Hilfe?',
  'components.art.need_help.text':
    'Wenn Sie mehr über dieses Kunstwerk erfahren möchten, sprechen Sie mit einem unserer Kuratoren und lassen Sie sich kostenlos von einem Experten beraten.',
  'components.art.need_help.button': 'Jetzt Kontakt aufnehmen',
  'components.art.requestMoreImages.title': 'Mehr Bilder anfordern',
  'components.art.requestMoreImages.titleTruncated': 'Mehr Bilder',
  'components.art.requestMoreImages.message':
    'Ich möchte weitere Bilder zu diesem Kunstwerk erhalten.',
  'components.art.arrangeViewing.title': 'Besichtigung',
  'components.art.arrangeViewing.titleTruncated': 'Besichtigung',
  'components.art.arrangeViewing.message':
    'Ich möchte weitere Bilder zu diesem Kunstwerk erhalten.',
  'components.art.more_artwork_by': 'Weitere Werke von {name}',
  'components.art.view_all_by': 'Alle Kunstwerke von {name} anzeigen',
  'components.art.similarArtworks': 'Optisch ähnliche Kunstwerke',
  'components.art.similarArtworksTruncated': 'Ähnliche Kunst',
  'components.art.similarCategoryLinks': 'Andere Kunstwerke in ähnlichen Stilen',
  'components.art.similarArtistLinks': 'Andere Künstler des gleichen Mediums',
  'components.art.recentlyViewedArtworks': 'Kürzlich angesehene Kunstwerke',
  'components.art.delivery': 'Lieferung',
  'components.art.commissionLabel': 'Ein ähnliches Kunstwerk in Auftrag geben',
  'components.art.commissionLabelAction': 'Wie lernen',
  'components.art.returns': 'Kostenlose Rücksendung',
  'components.art.returnsLabel': 'Kostenlose und einfache 14-tägige Rückgabe',
  'components.art.payInInstalments': 'In Raten zahlen',
  'components.art.worldwideShipping': 'Weltweiter sicherer Versand',
  'components.art.kazoartProLeasing':
    '<strong>Berufstätige:</strong> Kaufen Sie dieses Werk auf Leasingbasis',
  'components.art.findSimilarArtworks': 'Optisch ähnliche Kunstwerke finden',
  'components.art.gridNoArtworksDefault': 'Keine Kunstwerke gefunden.',
  'components.art.gridNoArtworks':
    '<p>Keine Kunstwerke gefunden. Bitte ändern Sie Ihre Such- oder Filterkriterien.</p><p><strong>AUF DER SUCHE NACH KÜNSTLERN?</strong> <a>Klicken Sie hier.</a></p>',
  'components.art.gridNoArtworksLinkTitle': 'Klicken Sie hier, um nach Künstlern zu suchen.',
  'components.art.trustedBy': 'Vertraut von führenden Marken, einschließlich {link}',
  'components.art.gridView': 'Rasteransicht',
  'components.art.detailsView': 'Galerieansicht',
  'components.art.openDetailsView': 'In der Galerieansicht öffnen',
  'components.art.viewInRoomBkgAlt': 'Blick in einen Innenraum',
  'components.artist.artist': 'Künstler',
  'components.artist.profile': 'Über',
  'components.artist.cv': 'Lebenslauf',
  'components.artist.cvHeader': 'Lebenslauf',
  'components.artist.artworks': '{name} Kunstwerke',
  'components.artist.studio': 'Atelier-Tagebuch',
  'components.artist.artistStudio': '{name} Atelier-Tagebuch',
  'components.artist.news': 'Neuigkeiten & Ausstellungen',
  'components.artist.artistNews': '{name} Neuigkeiten & Ausstellungen',
  'components.artist.featuredIn': 'Vorgestellt in',
  'components.artist.recentActivity': 'Neueste Aktivität',
  'components.artist.commissionTitle': 'Ein Kunstwerk von {name} in Auftrag geben',
  'components.artist.commissionSubtitle':
    'Wir können die Anfertigung eines neuen Werks, ganz speziell für Sie, arrangieren und beaufsichtigen.',
  'components.artist.findArtTitle': 'Nicht gefunden, wonach Sie gesucht haben?',
  'components.artist.findArtSubtitle':
    'Nutzen Sie unsere Filter oder {link}, um das perfekte Kunstwerk zu finden.',
  'components.artist.findArtSubtitleLink': 'Mit einem unserer Berater sprechen',
  'components.artist.findArtAction': 'Nach Kunst suchen',
  'components.artist.events': 'Zur Ansicht',
  'components.artist.viewCv': 'Lebenslauf ansehen',
  'components.artist.visitStudio': 'Das Atelier besuchen',
  'components.artist.bornIn': '{year} in {location}',
  'components.artist.bornInLabel': 'Datum und Ort der Geburt',
  'components.artist.livesLabel': 'Wohn- und Tätigkeitsort',
  'components.artist.education': 'Ausbildung',
  'components.artist.awards': 'Auszeichnungen',
  'components.artist.collections': 'Sammlungen',
  'components.artist.soloExhibitions': 'Ausgewählte Einzelausstellungen',
  'components.artist.groupExhibitions': 'Ausgewählte Gruppenausstellungen',
  'components.artist.bibliography': 'Literaturverzeichnis',
  'components.artist.expandButtonLabel': 'Vollständige Liste',
  'components.artist.printCV': 'Vollständigen Lebenslauf ausdrucken',
  'components.artist.noStudioFeed':
    'Derzeit gibt es keine Ateliertagebucheinträge von diesem Künstler.',
  'components.artist.noNews':
    'Derzeit gibt es keine Neuigkeiten oder Ausstellungen von diesem Künstler.',
  'components.artist.gridNoArtistsDefault': 'Keine Künstler gefunden.',
  'components.artist.gridNoArtists':
    '<p>Keine Künstler gefunden. Bitte ändern Sie Ihre Such- oder Filterkriterien.</p><p><strong>AUF DER SUCHE NACH KUNSTWERKEN?</strong> <a>Klicken Sie hier.</a></p>',
  'components.artist.gridNoArtistsLinkTitle': 'Klicken Sie hier, um nach Kunstwerken zu suchen.',
  'components.artist.unavailable': 'Dieser Künstler ist nicht verfügbar.',
  'components.artist.careerStage.established': 'Bestätigter Künstler',
  'components.artist.careerStage.emerging': 'Aufstrebender Künstler',
  'components.artist.careerStage.new': 'Neuer & bemerkenswerter Künstler',
  'components.event.type.soloShow': 'Einzelausstellung',
  'components.event.type.artFair': 'Kunstmesse',
  'components.event.type.talk': 'sprechen',
  'components.event.type.groupShow': 'Gruppenausstellung',
  'components.event.type.other': 'andere',
  'components.event.listTitle': 'Veranstaltungen',
  'components.event.rsvpBtnLabel.line1': 'WA',
  'components.event.rsvpBtnLabel.line2': 'wg',
  'components.event.rsvpStatus.0': 'Nein',
  'components.event.rsvpStatus.1': 'Ja',
  'components.event.rsvpStatus.2': 'Vielleicht',
  'components.event.headings.description': 'Beschreibung',
  'components.event.headings.dateAndTime': 'Datum und Uhrzeit',
  'components.event.headings.address': 'Adresse',
  'components.event.headings.attendees': 'Teilnehmer',
  'components.event.headings.share': 'Teilen Sie diese Veranstaltung',
  'components.event.addCalendar': 'Zum Kalender hinzufügenr',
  'components.event.visitSite': 'Besucherseite',
  'components.filters.showMore': 'Weitere Filter anzeigen',
  'components.filters.showLess': 'Weniger Filter anzeigen',
  'components.filters.clearFilters': 'Löschen',
  'components.filters.clearAll': 'Alle löschen',
  'components.filters.selectedCount': 'ausgewählt',
  'components.filters.rangeUnits': '{min} - {max} {units}',
  'components.filters.rangePrice': '{min} - {max} {currency}',
  'components.filters.price.label': 'Preis',
  'components.filters.size.label': 'Größe',
  'components.filters.orientation.label': 'Ausrichtung',
  'components.filters.color.label': 'Farbe',
  'components.filters.color.defaultText': 'Alle Farben',
  'components.filters.color.multiple': 'Ausgewählte Farben',
  'components.filters.color.title': 'Farben auswählen',
  'components.filters.color.noOptions': 'Keine Farben verfügbar',
  'components.filters.medium.label': 'Medium',
  'components.filters.medium.defaultText': 'Alle Medien',
  'components.filters.medium.multiple': 'Ausgewählte Medien',
  'components.filters.medium.title': 'Medien auswählen',
  'components.filters.medium.noOptions': 'Keine Medien verfügbar',
  'components.filters.style.label': 'Stil',
  'components.filters.style.defaultText': 'Alle Stile',
  'components.filters.style.multiple': 'Ausgewählte Stile',
  'components.filters.style.title': 'Stile auswählen',
  'components.filters.style.noOptions': 'Keine Stile verfügbar',
  'components.filters.subject.label': 'Thema',
  'components.filters.subject.defaultText': 'Alle Themen',
  'components.filters.subject.multiple': 'Ausgewählte Themen',
  'components.filters.subject.title': 'Themen auswählen',
  'components.filters.subject.noOptions': 'Keine Themen verfügbar',
  'components.filters.gender.label': 'Geschlecht',
  'components.filters.initial.label': 'Nachname',
  'components.filters.initial.defaultText': 'Alle Namen',
  'components.filters.initial.multiple': 'Ausgewählte Namen',
  'components.filters.initial.title': 'Namen auswählen',
  'components.filters.initial.noOptions': 'Keine Namen verfügbar',
  'components.filters.ships.label': 'Versand aus',
  'components.filters.ships.defaultText': 'Alle Länder',
  'components.filters.ships.multiple': 'Ausgewählte Länder',
  'components.filters.ships.title': 'Länder auswählen',
  'components.filters.ships.noOptions': 'Keine Länder verfügbar',
  'components.filters.stage.label': 'Karrierephase',
  'components.filters.stage.defaultText': 'Alle Karrierephasen',
  'components.filters.stage.multiple': 'Ausgewählte Karrierephasen',
  'components.filters.stage.title': 'Karrierephasen auswählen',
  'components.filters.stage.noOptions': 'Keine Karrierephasen verfügbar',
  'components.filters.region.label': 'Region',
  'components.filters.region.defaultText': 'Alle Regionen',
  'components.filters.region.multiple': 'Ausgewählte Regionen',
  'components.filters.region.title': 'Regionen auswählen',
  'components.filters.region.noOptions': 'Keine Regionen verfügbar',
  'components.filters.born.label': 'Nationalität',
  'components.filters.born.defaultText': 'Alle Nationalitäten',
  'components.filters.born.multiple': 'Ausgewählte Nationalitäten',
  'components.filters.born.title': 'Nationalitäten auswählen',
  'components.filters.born.noOptions': 'Keine Nationalitäten verfügbar',
  'components.filters.height.label': 'Höhe',
  'components.filters.width.label': 'Breite',
  'components.filters.showFilters': 'Filter anzeigen',
  'components.filters.hideFilters': 'Filter ausblenden',
  'components.filters.breadcrumbs.artist': '{dimension1} {dimension2}',
  'components.filters.breadcrumbs.art': '{dimension1} {dimension2}',
  'components.filters.breadcrumbs.collection': '{dimension1} {dimension2}',
  'components.filters.parentChildDimensions': '{dimension2} {dimension1}',
  'components.filters.mediumStyleLabel': '{style} {medium}',
  'components.cms.modules.homeimage.h3': 'Zeitgenössisch',
  'components.cms.modules.homeimage.h2': 'Kunst',
  'components.cms.modules.homeimage.h4': 'Handverlesen durch Experten',
  'components.cms.modules.homeimage.browse': 'STÖBERN SIE IN UNSERER',
  'components.cms.modules.homeimage.curated': 'KURATIERTEN',
  'components.cms.modules.homeimage.selection': 'AUSWAHL AN KUNST',
  'components.cms.modules.homeimage.browsing': 'Sie möchten sich nur umschauen?',
  'components.cms.modules.homeimage.curators': 'Lassen Sie sich von unseren Kuratoren anleiten',
  'components.cms.modules.homeimage.by': 'von',
  'components.cms.modules.legal.effectiveDate': 'Gültig',
  'components.cms.modules.legal.version': 'Version',
  'components.cms.modules.reviews.readAll': 'Lesen Sie alle unsere Bewertungen',
  'components.share.facebook': 'Facebook',
  'components.share.twitter': 'Twitter',
  'components.share.email': 'E-Mail',
  'components.share.whatsapp': 'WhatsApp',
  'components.share.pinterest': 'Pinterest',
  'components.share.options.facebook': 'Facebook',
  'components.share.options.twitter': 'Twitter',
  'components.share.options.email': 'E-Mail',
  'components.share.options.whatsapp': 'WhatsApp',
  'components.share.options.pinterest': 'Pinterest',
  'components.share.options.messenger': 'Messenger',
  'components.share.options.link': 'Link',
  'components.share.messages.article.facebook': 'Sehen Sie sich {title} auf @riseart an #RiseArt ',
  'components.share.messages.article.twitter': 'Sehen Sie sich {title} auf @riseart an #RiseArt ',
  'components.share.messages.article.pinterest':
    '\nSehen Sie sich {title} auf @riseart an #RiseArt\n\n{link}\n',
  'components.share.messages.article.emailSubject': 'Sehen Sie sich {title} auf @riseart an ',
  'components.share.messages.article.email':
    '\nSehen Sie sich {title} auf @riseart an #RiseArt\n\n',
  'components.share.messages.article.whatsapp':
    'Sehen Sie sich {title} auf @riseart an {link} #RiseArt ',
  'components.share.messages.article.messenger':
    'Sehen Sie sich {title} auf @riseart an {link} #RiseArt ',
  'components.share.messages.article.link':
    'Sehen Sie sich {title} auf @riseart an {link} #RiseArt ',
  'components.share.messages.guide.facebook': 'Sehen Sie sich {title} auf @riseart an #RiseArt ',
  'components.share.messages.guide.twitter': 'Sehen Sie sich {title} auf @riseart an #RiseArt ',
  'components.share.messages.guide.pinterest':
    '\nSehen Sie sich {title} auf @riseart an #RiseArt\n\n{link}\n',
  'components.share.messages.guide.emailSubject': 'Sehen Sie sich {title} auf @riseart an ',
  'components.share.messages.guide.email':
    '\n  Sehen Sie sich {title} auf @riseart an #RiseArt\n\n',
  'components.share.messages.guide.whatsapp':
    'Sehen Sie sich {title} auf @riseart an {link} #RiseArt ',
  'components.share.messages.guide.messenger':
    'Sehen Sie sich {title} auf @riseart an {link} #RiseArt ',
  'components.share.messages.guide.link': 'Sehen Sie sich {title} auf @riseart an {link} #RiseArt ',
  'components.share.messages.artwork_detail.facebook':
    'Sehen Sie sich {name} von {artist} auf Rise Art an ',
  'components.share.messages.artwork_detail.twitter':
    'Sehen Sie sich {name} von {artist} auf Rise Art an ',
  'components.share.messages.artwork_detail.pinterest':
    '\nSehen Sie sich {name} von {artist} auf Rise Art an\n\n{url}\n',
  'components.share.messages.artwork_detail.emailSubject':
    'Sehen Sie sich {name} von {artist} auf Rise Art an',
  'components.share.messages.artwork_detail.email':
    '\nSehen Sie sich dieses Kunstwerk auf Rise Art an und lassen Sie mich wissen, was Sie davon halten!\n\n{url}',
  'components.share.messages.artwork_detail.whatsapp':
    'Sehen Sie sich {name} von {artist} auf Rise Art an ',
  'components.share.messages.artwork_detail.messenger':
    'Sehen Sie sich {name} von {artist} auf Rise Art an ',
  'components.share.messages.artwork_detail.link':
    'Sehen Sie sich {name} von {artist} auf Rise Art an ',
  'components.share.messages.collection_detail.facebook':
    'Sehen Sie sich diese Kollektion von Rise Art an: {name} ',
  'components.share.messages.collection_detail.twitter':
    'Ich bewundere gerade die Sammlung {name} auf Rise Art #{name} ',
  'components.share.messages.collection_detail.pinterest': '\n{name} | Rise Art\n\n{url}\n',
  'components.share.messages.collection_detail.emailSubject':
    'Diese Sammlung von Rise Art werden Sie lieben: {name}',
  'components.share.messages.collection_detail.email':
    '\nHallo!\n\nIch bewundere gerade die Sammlung {name} auf Rise Art. Sehen Sie sie sich an und lassen Sie mich wissen, was Sie davon halten.\n\nLink: {url}',
  'components.share.messages.collection_detail.whatsapp':
    'Sehen Sie sich diese Sammlung von Rise Art an: {name}',
  'components.share.messages.collection_detail.messenger':
    'Sehen Sie sich diese Sammlung von Rise Art an: {name}',
  'components.share.messages.collection_detail.link':
    'Sehen Sie sich diese Sammlung von Rise Art an: {name}',
  'components.share.messages.quiz_personality.facebook':
    'Meine Kunstpersönlichkeit ist {name}. Erfahren Sie mit dem Persönlichkeitstest von Rise Art, welcher Kunststil am besten Ihren Geschmack trifft.',
  'components.share.messages.quiz_personality.twitter':
    'Meine Kunstpersönlichkeit ist {name}. Erfahren Sie mit dem Persönlichkeitstest von Rise Art, welcher Kunststil am besten Ihren Geschmack trifft.#EntdeckenSieIhrenStil',
  'components.share.messages.quiz_personality.pinterest':
    'Meine Kunstpersönlichkeit ist {name}. Erfahren Sie mit dem Persönlichkeitstest von Rise Art, welcher Kunststil am besten Ihren Geschmack trifft.',
  'components.share.messages.quiz_personality.emailSubject': 'Meine Kunstpersönlichkeit ist {name}',
  'components.share.messages.quiz_personality.email':
    'Hi :)\n\nIch habe gerade den Persönlichkeitstest von Rise Art gemacht und anscheinend bin ich {name}. Sehen Sie sich an, was dies über meinen Kunststil und Geschmack aussagt. Teilen Sie mir mit, wenn Sie den Test machen – mal sehen, ob wir zusammenpassen!\n\n\nLink: {link}',
  'components.share.messages.quiz_personality.whatsapp':
    'Hi :) Ich habe gerade den Persönlichkeitstest von Rise Art gemacht und anscheinend bin ich {name}. Sehen Sie sich an, was dies über meinen Kunststil und Geschmack aussagt. Teilen Sie mir mit, wenn Sie den Test machen – mal sehen, ob wir zusammenpassen!',
  'components.share.messages.quiz_personality.messenger': '',
  'components.share.messages.quiz_personality.link': '',
  'components.share.messages.artistProfile.facebook': 'Sehen Sie sich {artist} auf Rise Art an ',
  'components.share.messages.artistProfile.twitter': 'Sehen Sie sich {artist} auf Rise Art an ',
  'components.share.messages.artistProfile.pinterest':
    '\nSehen Sie sich {artist} auf Rise Art an\n\n{url}\n',
  'components.share.messages.artistProfile.emailSubject': 'Sehen Sie sich {artist}',
  'components.share.messages.artistProfile.email':
    '\nSehen Sie sich {artist} auf Rise Art an\n\n{url}',
  'components.share.messages.artistProfile.whatsapp': 'Sehen Sie sich {artist} auf Rise Art an ',
  'components.share.messages.artistProfile.messenger': 'Sehen Sie sich {artist} auf Rise Art an ',
  'components.share.messages.artistProfile.link': 'Sehen Sie sich {artist} auf Rise Art an ',
  'components.share.messages.event.facebook':
    'Schauen Sie sich die Veranstaltung {name} auf Rise Art @riseart #RiseArt ',
  'components.share.messages.event.twitter':
    'Schauen Sie sich die Veranstaltung {name} auf Rise Art @riseart #RiseArt ',
  'components.share.messages.event.pinterest':
    '\nSchauen Sie sich die Veranstaltung {name} auf Rise Art @riseart #RiseArt\n\n{link}\n',
  'components.share.messages.event.emailSubject':
    'Schauen Sie sich die Veranstaltung {name} auf Rise Art @riseart #RiseArt ',
  'components.share.messages.event.email':
    '\nSchauen Sie sich die Veranstaltung {name} auf Rise Art @riseart #RiseArt\n\n',
  'components.share.messages.event.whatsapp':
    'Schauen Sie sich die Veranstaltung {name} auf Rise Art {link} @riseart #RiseArt ',
  'components.share.messages.event.messenger':
    'Schauen Sie sich die Veranstaltung {name} auf Rise Art {link} @riseart #RiseArt ',
  'components.share.messages.event.link':
    'Schauen Sie sich die Veranstaltung {name} auf Rise Art {link} @riseart #RiseArt ',
  'components.quiz.start_test': 'Test starten',
  'components.quiz.landing_page_arrow':
    'Möchten Sie mehr über die wissenschaftlichen Hintergründe erfahren?',
  'components.quiz.back_button': 'zurück',
  'components.quiz.next_button': 'weiter',
  'components.quiz.ready_button': 'Ich bin bereit',
  'components.quiz.lets_go_button': "Los geht's",
  'components.quiz.swipe_left': 'Wischen Sie nach LINKS, wenn Ihnen das Bild NICHT gefällt',
  'components.quiz.swipe_right': 'Wischen Sie nach RECHTS, wenn wenn Ihnen das Bild gefällt',
  'components.quiz.swipe_btn_text': 'Verstanden',
  'components.quiz.share_results_title':
    'Teilen Sie Ihre Ergebnis und finden Sie heraus, wie Ihre Freunde im Vergleich zu Ihnen abschneiden',
  'components.quiz.share_art_personality': 'Teilen Sie Ihre Kunstpersönlichkeit',
  'components.quiz.share_art_personality_hint':
    'und finden Sie heraus, wie Ihre Freunde im Vergleich zu Ihnen abschneiden',
  'components.quiz.share_results': 'Teilen Sie Ihr Ergebnis',
  'components.quiz.result_banner_heading': 'Sie sind',
  'components.quiz.result_score_averages': 'Anteil der Bevölkerung:',
  'components.quiz.result_compared_title': 'Das sind Sie im Vergleich zu anderen',
  'components.quiz.result_collection_title': 'Sammlungen für {name}',
  'components.quiz.result_artists_title': 'Künstler für {name}',
  'components.quiz.result_recommended_title': 'Kunst für {name}',
  'components.quiz.result_recommended_hint': 'auf der Grundlage Ihrer Punktzahl',
  'components.quiz.result_recommend_title':
    'Kennen Sie jemanden, der den Test gerne machen würde? Schicken Sie dieser Person den Test!',
  'components.quiz.results_chart_score_label': 'Sie',
  'components.quiz.discover_your_personality': 'Entdecken Sie Ihre Kunstpersönlichkeit',
  'components.quiz.discover_visual_preferences':
    'Finden Sie heraus, was Ihre visuellen Vorlieben über Sie aussagen.',
  'components.quiz.take_test': 'Machen Sie den Test',
  'components.quiz.art_personality': 'KUNSTPERSÖNLICHKEIT',
  'components.quiz.other_art_personalities': 'Andere Kunstpersönlichkeiten',
  'components.quiz.profile_compared_to_others':
    'Dies sind die Merkmalsausprägungen von {name} im Vergleich zu anderen',
  'components.quiz.result_trait_arousing':
    'Vielfältige Verwendung von Farben, Rot, intensive Szenen, scharfe, unregelmäßige Formen, Kubismus.',
  'components.quiz.result_trait_minimalist':
    'Einfach, strukturiert, wiederkehrende Muster, wiederkehrende Themen, einheitliche Merkmale, geometrisch.',
  'components.quiz.result_trait_figurative':
    'Landschaften, Portraits, gegenständlich, nicht reflektierend, kontextuelle Informationen nicht erforderlich, kohärentes Narrativ, traditionell, Impressionismus.',
  'components.quiz.result_trait_shocking':
    'Nicht linear, möglicherweise gewalttätige, morbide, sexuelle Bilder, feindselig.',
  'components.quiz.auth_page_text.main_text': 'Herzlichen Glückwunsch!',
  'components.quiz.auth_page_text.hint_text':
    'Bitte geben Sie Ihre Daten ein, um Ihre Kunstpersönlichkeit zu entdecken.',
  'components.quiz.auth_page_text.bottom_text':
    'Wenn Sie diese Seite ohne Angabe Ihrer Daten verlassen, können wir Ihr Ergebnis weder speichern noch auswerten.',
  'components.quiz.auth_page_text.continue_with': 'Weiter mit',
  'components.quiz.auth_page_text.send_email_results':
    'oder melden Sie sich an, um eine Mitteilung über Ihr Ergebnis zu erhalten.',
  'components.quiz.auth_page_text.subscribe_button': 'Mein Ergebnis abrufen',
  'components.quiz.validation_messages.default_message':
    'Die von Ihnen eingegebenen Informationen sind ungültig, bitte korrigieren Sie sie.',
  'components.quiz.errors.questions_not_loaded':
    'Wir konnten die Seite nicht vom Server laden. Bitte überprüfen Sie Ihre Internetverbindung und aktualisieren Sie diese Seite.',
  'components.quiz.errors.result_page_not_loaded':
    'Es ist uns nicht gelungen, die Testergebnisse vom Server zu laden. Bitte überprüfen Sie Ihre Internetverbindung und aktualisieren Sie diese Seite.',
  'components.quiz.errors.submit_answers':
    'Wir konnten Ihre Antworten nicht an den Server übermitteln.',
  'components.collections.curator_text': 'Kuratiert von {name} {curatorText}',
  'components.collections.curator_text_short': 'Kuratiert von {name}',
  'components.collections.errors.banner_data_not_loaded':
    'Wir konnten die Seite nicht vom Server laden. Bitte überprüfen Sie Ihre Internetverbindung und aktualisieren Sie diese Seite.',
  'components.collections.errors.collection_list_not_loaded':
    'Wir konnten die Sammlungsliste nicht vom Server laden. Bitte überprüfen Sie Ihre Internetverbindung und aktualisieren Sie diese Seite.',
  'components.collections.list_title': 'Unsere kuratierten Sammlungen',
  'components.auth.effective_date': 'Gültig ab: {date}',
  'components.auth.facebook_signin': 'Mit Facebook anmelden',
  'components.auth.facebook_signup': 'Mit Facebook registrieren',
  'components.auth.google_signin': 'Mit Google anmelden',
  'components.auth.google_signup': 'Mit Google registrieren',
  'components.auth.signin': 'Bei Rise Art anmelden',
  'components.auth.signup': 'Mit Ihrem Rise-Art-Konto anmelden',
  'components.auth.signup_social': 'Bei Rise Art registrieren',
  'components.auth.signupTab': 'Neu bei Rise Art?',
  'components.auth.loginTab': 'Bereits registriert?',
  'components.media.noVIdeoSupport': 'Ihr Browser unterstützt das Video-Tag nicht.',
  'components.pagination.page': 'Seite',
  'components.pagination.next': 'Nächste Seite',
  'components.pagination.previous': 'Vorherige Seite',
  'components.pagination.infiniteCounter':
    '{count, plural, =0 {keine Werke gefunden} other {{countFormatted} Werke zeigen}}',
  'components.pagination.sliderCounter':
    '{total, plural, =0 {keine Werke gefunden} other {zeigt {from}-{to} von {totalFormatted}}}',
  'components.cart.myBasket': 'Mein Warenkorb',
  'components.cart.yourBasket': 'Ihr Warenkorb',
  'components.cart.yourRental': 'Von Ihnen gemietet',
  'components.cart.printOrder': 'Bestellung drucken',
  'components.cart.freeShipping': 'Kostenlos',
  'components.cart.total': 'Gesamt',
  'components.cart.subtotal': 'Zwischensumme',
  'components.cart.shipping': 'Versand',
  'components.cart.qty': 'Menge',
  'components.cart.secure': 'Sichere',
  'components.cart.sslEncryption': 'SSL-Verschlüsselung',
  'components.cart.discountCode': 'Rabatt',
  'components.cart.credit': 'Guthaben',
  'components.cart.giftCard': 'Geschenkgutschein',
  'components.cart.continueToCheckout': 'Weiter zur Kasse',
  'components.cart.paymentOptions': 'Zahlungsoptionen',
  'components.cart.safeCheckout': 'Sichere Bezahlung',
  'components.cart.rentalCreditNote':
    'Um Ihre Zahlungsangaben zu bestätigen und Ihr Mietkonto einzurichten, müssen wir Ihre Karte mit einem Minimalbetrag belasten.',
  'components.cart.instalmentsHint':
    'Möchten Sie den Betrag mit 0 % Zinsen auf 10 Monate verteilen?',
  'components.cart.customDutiesHint': 'Es können Zollgebühren anfallen.',
  'components.cart.checkHere': 'Hier prüfen',
  'components.cart.countItems':
    '{count, plural, =0 {keine Artikel} one {# Artikel} other {# Artikel}}',
  'components.cart.rentalDayDuration': '{days, plural, one {# Tag Miete} other {# Tage Miete}}',
  'components.cart.rentalForthnightDuration':
    '{fortnights, plural, one {# 14-Tage Miete} other {# 14-Tage Miete}}',
  'components.cart.rentalMonthDuration':
    '{months, plural, one {# Monat Miete} other {# Monate Miete}}',
  'components.cart.rentalYearDuration': '{years, plural, one {# Jahr Miete} other {# Jahre Miete}}',
  'components.cart.offer': 'Angewandtes Angebot',
  'components.cart.viewBasket': 'Warenkorb ansehen',
  'components.cart.checkout': 'Kasse',
  'components.cart.usingCouponCode': 'Gutscheincode {couponCode} verwenden',
  'components.cart.customCharges': 'Es können Zollgebühren anfallen, siehe {link}',
  'components.cart.customChargesTitle': 'Versand- und Zollgebühren',
  'components.cart.sidebarBasketEmptyText': 'Sie haben keine Artikel in Ihrem Warenkorb.',
  'components.cart.basketEmptyTitle': 'Ihr Warenkorb ist leer',
  'components.cart.basketEmptyText':
    'Sie haben keine Artikel in Ihrem Warenkorb. Sie können Artikel von Ihrer Wunschliste in den Warenkorb legen oder auf {link} klicken, um Ihren Einkauf fortzusetzen.',
  'components.cart.rentalEmptyTitle': 'Keine Anmietung ausgewählt',
  'components.cart.rentalEmptyText':
    'Zurzeit ist keine Anmietung ausgewählt. Sie können die Kunstwerke von ihrer jeweiligen Detailseite aus mieten. Klicken Sie auf {link}, um Ihren Einkauf fortzusetzen.',
  'components.cart.yourCredit': 'Ihr Guthaben',
  'components.cart.gotGiftCart': 'Haben Sie einen Geschenkgutschein?',
  'components.cart.useDiscount': 'Verwenden Sie Ihren Rabattcoupon.',
  'components.cart.noVouchers': 'Es sind keine Gutscheine mit Ihrem Konto verknüpft.',
  'components.cart.signInCredit1': 'Bitte ',
  'components.cart.signInCredit2': 'registrieren Sie sich,',
  'components.cart.signInCredit3': ' um Ihr Guthaben zu nutzen.',
  'components.cart.redeemCredit1': 'Bitte ',
  'components.cart.redeemCredit2': 'hier einlösen,',
  'components.cart.redeemCredit3':
    ' um Ihr Guthaben auf Ihr Konto zu übertragen und es an der Kasse ausgeben zu können.',
  'components.cart.signInRedeem1': 'Bitte ',
  'components.cart.signInRedeem2': 'melden Sie sich an,',
  'components.cart.signInRedeem3':
    ' um Ihre Kreditkarten nutzen zu können und Ihr Guthaben auf Ihr Konto zu übertragen, damit Sie es an der Kasse ausgeben können.',
  'components.cart.useCredit': 'Mein Guthaben für diese Bestellung verwenden',
  'components.cart.redeemGiftCard': 'Geschenkgutschein einlösen',
  'components.cart.redeemGiftDesc':
    'Übertragen Sie Ihr Guthaben auf Ihr Konto, um es an der Kasse auszugeben',
  'components.cart.giftCardCode': 'Gutschein-Code',
  'components.cart.giftCardSecurity': 'Sicherheitscode',
  'components.cart.giftCardSecurityDesc':
    'Wenn Ihr Gutschein einen Sicherheitscode enthält, geben Sie diesen bitte ein, um Ihr Guthaben einzulösen.',
  'components.cart.shipsReadyToHangTooltipHeading': 'Hängefertiges Mietobjekt',
  'components.cart.shipsReadyToHangTooltipDescription':
    'Alle Mietobjekte werden hängefertig geliefert.',
  'components.cart.confirmationHeading': 'Vielen Dank für Ihre Bestellung',
  'components.cart.confirmationHeadingShort': 'Vielen Dank',
  'components.cart.confirmationDetailsHeading': 'Bestelldaten #{orderId}',
  'components.cart.confirmationDetailsHeadingShort': 'Bestellung #{orderId}',
  'components.cart.confirmationDetailsDescription':
    'Sie erhalten in Kürze eine Bestätigungs-E-Mail über {email}',
  'components.cart.confirmationSubscribeHeading': 'Erhalten Sie unsere Tipps per E-Mail',
  'components.cart.confirmationSubscribeButton': 'Ja, ich bin dabei',
  'components.cart.confirmationSubscribeDescription':
    'Wir bieten Ihnen Anregungen für die Auswahl von Kunstwerken für Ihr Zuhause, zeigen Ihnen, was Innenarchitekten aussuchen, und geben Ihnen jede Woche einen Überblick über unsere Neuheiten.',
  'components.cart.confirmationBlogHeading': 'Frisch aus unserem Blog',
  'components.cart.confirmationReviewHint':
    'Bitte überprüfen Sie Ihre Bestellung und kontaktieren Sie uns, wenn Sie Fragen oder Probleme haben. Alle hinterlegten Karten und Adressen stehen Ihnen für Ihren nächsten Einkauf zur Verfügung und können über Ihr Kundenkonto bearbeitet werden. ',
  'components.cart.confirmationReviewReturnPolicy':
    'Für Informationen über Rücksendungen lesen Sie bitte unsere Geschäftsbedingungen (AGB). ',
  'components.cart.confirmationReviewActionBtn': 'Einkauf fortsetzen',
  'components.cart.creditApplied': 'Das Guthaben wurde dem Warenkorb hinzugefügt',
  'components.cart.creditRemoved': 'Das Guthaben wurde aus dem Warenkorb entfernt',
  'components.checkout.steps.info': 'Meine Angaben',
  'components.checkout.steps.infoShort': 'Kontakt',
  'components.checkout.steps.shipping': 'Lieferadresse',
  'components.checkout.steps.shippingShort': 'Lieferung',
  'components.checkout.steps.addShipping': 'Neue Lieferadresse',
  'components.checkout.steps.editShipping': 'Lieferadresse bearbeiten',
  'components.checkout.steps.billing': 'Zahlung',
  'components.checkout.steps.billingAddress': 'Rechnungsadresse',
  'components.checkout.cards.visa': 'Visa',
  'components.checkout.cards.master': 'Mastercard',
  'components.checkout.cards.maestro': 'Maestro',
  'components.checkout.cards.amex': 'American Express',
  'components.checkout.cards.diners': 'Diners Club',
  'components.checkout.cards.discover': 'Discover',
  'components.checkout.cards.jcb': 'JCB',
  'components.checkout.cards.unionpay': 'UnionPay',
  'components.checkout.cards.unknown': 'Unbekannte Karte',
  'components.checkout.cards.default': 'Karte',
  'components.checkout.orderDate': 'Bestelldatum',
  'components.checkout.yourInformation': 'Ihre Angaben',
  'components.checkout.paymentMethod': 'Zahlungsmethode',
  'components.checkout.continueToDelivery': 'Weiter zur Lieferung',
  'components.checkout.deliverToAddress': 'Lieferadresse',
  'components.checkout.continueToReview': 'Weiter zur Überprüfung der Bestellung',
  'components.checkout.deliveryHint':
    'Für den Erhalt Ihres Kunstwerks muss bei der Zustellung eine Unterschrift geleistet werden. Bitte stellen Sie sicher, dass unter Ihrer angegebenen Adresse jemand anwesend ist, um das Kunstwerk in Empfang zu nehmen.',
  'components.checkout.paymentHint': 'Alle Transaktionen erfolgen sicher und verschlüsselt.',
  'components.checkout.reviewOrder': 'Bestellung prüfen',
  'components.checkout.reviewOrderHint':
    'Zu diesem Zeitpunkt werden Ihnen keine Kosten in Rechnung gestellt. Sie können Ihre Bestellung überprüfen, bevor Sie sie abschicken.',
  'components.checkout.placeOrder': 'Bestellung aufgeben',
  'components.checkout.placeOrderHint':
    'Mit Ihrer Bestellung erklären Sie sich mit unseren {terms}, {policy} und {returnPolicy} einverstanden. Sie erklären sich auch damit einverstanden, dass einige Ihrer Daten von Rise Art gespeichert werden, um Ihr zukünftiges Einkaufserlebnis zu verbessern.',
  'components.checkout.checkoutButtonExternal': 'Weiter zu {paymentType}',
  'components.checkout.creditHeading': 'Geschenkgutscheine und Guthaben',
  'components.checkout.creditLabel':
    'Mein Guthaben für diese Bestellung verwenden. Ihr verfügbares Gesamtguthaben beträgt {credit}',
  'components.checkout.creditDescription1':
    'Haben Sie einen Geschenkgutschein? Fügen Sie den Betrag Ihrem Gutschein-Guthaben hinzu, ',
  'components.checkout.creditDescription2': ' damit Sie ihn an der Kasse einlösen können.',
  'components.checkout.cardValidThru': 'Gültig bis',
  'components.checkout.addPayment': 'Zahlung ändern',
  'components.checkout.usePayment': 'Zahlung auswählen',
  'components.checkout.useAddress': 'Diese Adresse verwenden',
  'components.checkout.paymentDetails': 'Einzelheiten zur Zahlung',
  'components.checkout.yourSavedCards': 'Ihre hinterlegten Karten',
  'components.checkout.usedCredits': 'Guthaben bei Rise Art',
  'components.checkout.useCredits':
    '<strong>Mein Guthaben</strong> für diese Bestellung verwenden:',
  'components.checkout.useCard':
    'Für diese Bestellung die folgende <strong>Karte</strong> verwenden:',
  'components.checkout.useCardAndCredits':
    '<strong>Mein Guthaben</strong> und die folgende <strong>Karte</strong> für diese Bestellung verwenden:',
  'components.checkout.usePaymentType': '<strong>{paymentType}</strong> verwenden',
  'components.checkout.usePaymentTypeAndCredits':
    'Verwenden Sie <strong>mein Guthaben</strong> und <strong>{paymentType}</strong> für diese Bestellung',
  'components.checkout.securityCodeHintTitle': 'Sicherheitscode',
  'components.checkout.securityCodeHintDescription':
    'Bei Visa und Mastercard sind dies die letzten drei Ziffern auf der Rückseite Ihrer Karte neben dem Unterschriftsstreifen',
  'components.checkout.savePayment':
    'Kartendaten und Rechnungsadresse für das nächste Mal speichern',
  'components.checkout.saveShippingAddress': 'Lieferadresse für das nächste Mal speichern',
  'components.checkout.declinedPayment':
    'Die Zahlung wurde abgelehnt. Wenn Sie weiterhin Probleme mit der Zahlung haben, kontaktieren Sie uns bitte.',
  'components.checkout.notLoadedStripe':
    'Bitte überprüfen Sie Ihre Netzwerkverbindung und versuchen Sie es erneut. Wenn Sie weiterhin Probleme mit der Zahlung haben, kontaktieren Sie uns bitte.',
  'components.checkout.footerMenu.payment': 'Sichere Bezahlung',
  'components.checkout.footerMenu.return': 'Informationen zum Rückgaberecht',
  'components.checkout.footerMenu.returnTruncated': 'Rücksendung',
  'components.checkout.footerMenu.contact': 'Kontaktieren Sie uns',
  'components.checkout.footerMenu.contactTruncated': 'Kontakte',
  'components.checkout.order.state.new': 'Neu',
  'components.checkout.order.state.pending_payment': 'Ausstehende Zahlung',
  'components.checkout.order.state.processing': 'Verarbeitung',
  'components.checkout.order.state.complete': 'Vollständig',
  'components.checkout.order.state.closed': 'Abgeschlossen',
  'components.checkout.order.state.canceled': 'Abgebrochen',
  'components.checkout.order.state.holded': 'Angehalten',
  'components.checkout.order.state.payment_review': 'Zahlungsüberprüfung',
  'components.checkout.paymentDescription': '{paymentType}',
  'components.checkout.paymentDescriptionAccount': '{paymentType}-Konto {account}',
  'components.checkout.paymentType.card': 'Kredit- oder Debitkarte',
  'components.checkout.paymentType.paypal': 'PayPal',
  'components.checkout.paymentType.google_pay': 'Google Pay',
  'components.checkout.paymentType.apple_pay': 'Apple Pay',
  'components.checkout.paymentType.klarna': 'Klarna',
  'components.checkout.paymentType.sepa': 'Sepa Debit',
  'components.checkout.paymentType.sofort': 'Sofort',
  'components.checkout.paymentType.giropay': 'Giropay',
  'components.checkout.paymentType.wechat_pay': 'WeChat Pay',
  'components.checkout.paymentType.stripe': 'Stripe Zahlungen',
  'components.checkout.paymentCategory.pay_later': 'Später bezahlen',
  'components.checkout.paymentCategory.pay_with_financing': 'Bezahlen mit Finanzierung',
  'components.checkout.paymentCategory.pay_now': 'Jetzt bezahlen',
  'components.checkout.paymentCategory.pay_in_installments': 'In Raten zahlen',
  'components.checkout.orderReviewMessage':
    'Ihre Bestellung wartet auf die Zahlungsbestätigung. Sobald Ihre Zahlung abgeschlossen ist, erhalten Sie eine Bestellbestätigungs-E-Mail. Sollten Sie Fragen haben, kontaktieren Sie uns bitte unter <a>email</a> und geben Sie unten Ihre Bestellnummer an.',
  'components.checkout.orderCancelMessage':
    'Die Zahlung für Ihre Bestellung wurde storniert oder abgelehnt. Sollten Sie Fragen haben, kontaktieren Sie uns bitte unter <a>email</a> und geben Sie unten Ihre Bestellnummer an.',
  'components.checkout.paymentCancelMessage':
    'Die Zahlung für Ihre Bestellung wurde storniert oder abgelehnt. Bitte überprüfen Sie Ihre Zahlungsinformationen und versuchen Sie es erneut.',
  'components.countdown.days': 'tage',
  'components.countdown.hours': 'std',
  'components.countdown.minutes': 'min',
  'components.countdown.seconds': 'sek',
  'components.appMessages.blackFriday.title':
    'GÖNNEN SIE SICH LUXUS: Black Friday Rabatte bis zu 25%',
  'components.appMessages.blackFriday.titleMobile':
    'GÖNNEN SIE SICH LUXUS<br></br>Black Friday Rabatte bis zu 25%',
  'components.appMessages.blackFriday.titleCondensed': 'Black Friday Rabatte bis zu 25%',
  'components.appMessages.blackFriday.countdownLabelTruncated': 'Endet',
  'components.appMessages.blackFriday.art15':
    '{currencySign}500+ ausgeben und <div><strong>10% Sparen</strong> mit <strong>ART10</strong></div>',
  'components.appMessages.blackFriday.art250':
    '{currencySign}1500+ ausgeben und <div><strong>{currencySign}200 Sparen</strong> mit <strong>ART200</strong></div>',
  'components.appMessages.blackFriday.art500':
    '{currencySign}3000+ ausgeben und <div><strong>{currencySign}500 Sparen</strong> mit <strong>ART500</strong></div>',
  'components.appMessages.blackFriday.art1000':
    '{currencySign}5000+ ausgeben und <div><strong>{currencySign}1000 Sparen</strong> mit <strong>ART1000</strong></div>',
  'components.appMessages.blackFriday.art8000':
    '{currencySign}7000+ ausgeben und <div><strong>{currencySign}1750 Sparen</strong> mit <strong>ART1750</strong></div>',
  'components.appMessages.artistSpotlight.title':
    'Künstler-Spotlight-Serie: <strong>GENIESSEN SIE 15% RABATT auf <a>ausgewählte Kunstwerke</a>.</strong>',
  'components.appMessages.artistSpotlight.titleMobile': 'Künstler-Spotlight-Serie',
  'components.appMessages.artistSpotlight.subTitleMobile':
    '<strong>GENIESSEN SIE 15% RABATT auf <a>ausgewählte Kunstwerke</a>.</strong>',
  'components.appMessages.artistSpotlight.titleCondensed': 'Künstler-Spotlight-Serie',
  'components.appMessages.artistSpotlight.titleCondensedMobile': 'Künstler-Spotlight-Serie',
  'components.appMessages.artistSpotlight.titleExtended':
    'Künstler-Spotlight-Serie: <strong>GENIESSEN SIE 15% RABATT auf <a>ausgewählte Kunstwerke</a>.</strong>',
  'components.appMessages.artistSpotlight.titleExtendedMobile': 'Künstler-Spotlight-Serie',
  'components.appMessages.artistSpotlight.subTitleExtendedMobile':
    'GENIESSEN SIE 15% RABATT auf <strong><a>ausgewählte Kunstwerke</a>.</strong>',
  'components.appMessages.artistSpotlight.countdownLabelTruncated': ' ',
  'components.appMessages.artistSpotlight.codeExtended':
    'Verwenden Sie <strong>SPOTLIGHT15</strong> beim Auschecken.',
  'components.appMessages.artistSpotlight.codeExtendedMobile':
    'Verwenden Sie <strong>SPOTLIGHT15</strong> beim Auschecken. Angebot endet am 28/04.',
  'components.appMessages.artistSpotlight.code':
    ' Verwenden Sie <strong>SPOTLIGHT15</strong> beim Auschecken. Angebot endet am 28/04.',
  'components.appMessages.promotions.visualSearch.title':
    'Klicken Sie auf {visualSimilarIcon} um ähnliche Kunstwerke zu entdecken',
  'components.appMessages.promotions.visualSearch.text':
    'Verwenden Sie die visuelle Suche, um Kunstwerke mit einem ähnlichen Stil zu finden',
  'components.appMessages.promotions.visualSearch.action': 'Jetzt Ausprobieren',
  'components.appMessages.promotions.returns.title': 'Kostenlose und einfache 14-tägige Rückgabe',
  'components.appMessages.promotions.advisors.title': 'Sprechen Sie mit unseren Kuratoren',
  'components.appMessages.promotions.advisors.action': 'Kontakt Aufnehmen',
  'components.appMessages.promotions.reviews.title': 'Vertraut von Sammlern und führenden Marken.',
  'components.appMessages.promotions.reviews.text':
    'Sehen Sie unsere neueste Zusammenarbeit mit Soho Home.',
  'components.appMessages.promotions.reviews.action': 'Soho Home x Rise art',
  'components.appMessages.promotions.filters.title':
    'Verfeinern Sie Ihre Suche. Finden Sie das perfekte Kunstwerk',
  'components.appMessages.promotions.filters.text':
    'Filtern Sie nach Größe, Medium, Preis und mehr',
  'components.appMessages.promotions.filters.action': 'Jetzt Ausprobieren',
  'components.blog.landingTitle': 'Editorial',
  'components.blog.curatorsPicks': 'Auswahl des Kurators',
  'components.blog.mostRead': 'Meistgelesen',
  'components.blog.moreFrom': 'Mehr von',
  'components.blog.relatedArtworks': 'Verwandte Kunstwerke',
  'components.blog.furtherReading': 'Weiterführende Literatur',
  'components.blog.subscribe.title': 'Abonnieren Sie unseren Newsletter',
  'components.blog.subscribe.subtitle':
    'Erhalten Sie frühzeitigen Zugang zu Geschichten über die Künstler sowie zu neuen Kunstwerken und Aktionen',
  'components.blog.subscribe.submitBtn': 'Registrierung',
  'components.guides.landingTitle': 'Kunstleitfäden',
  'components.guides.landingDescription':
    'Entdecken Sie die neuesten Kunstnachrichten, aktuelle Neuerscheinungen und Veranstaltungen. Ob Sie sich nun über Neuigkeiten aus der Kunstwelt auf dem Laufenden halten wollen, mehr Einblick in die Kunstgeschichte gewinnen möchten oder sich für die wichtigsten Kunstströmungen der Szene interessieren – im Rise Art Blog finden Sie alles, was Sie über zeitgenössische Kunst wissen müssen.',
  'components.guides.furtherReading': 'Verwandte Leitfäden',
  'components.guides.readNext': 'Dies als nächstes lesen',
  'components.guides.subscribe.title': 'Auf dem Laufenden bleiben',
  'components.guides.subscribe.subtitle':
    'Erhalten Sie frühzeitigen Zugang zu Inhalten wie diesen und vielem mehr',
  'components.guides.subscribe.submitBtn': 'Registrierung',
  'components.wishlist.title': 'Mein Wunschzettel',
  'components.wishlist.noItems.title': 'Speichern Sie Ihre Lieblingskunstwerke hier',
  'components.wishlist.noItems.descriptionLine1':
    'Klicken Sie einfach auf {wishlistIcon} unter dem Kunstwerk, um es zu Ihrer Wunschliste hinzuzufügen.',
  'components.wishlist.noItems.descriptionLine2':
    'Probieren Sie {visuallySimilarIcon}, um visuell ähnliche Kunst zu entdecken.',
  'components.wishlist.noItems.button': 'Jetzt in der Kunst',
  'components.follow.noItems.title': 'Speichern Sie Ihre Künstler hier',
  'components.follow.noItems.descriptionLine1':
    'Verwenden Sie {followIcon} um Künstler zu Ihrer Liste hinzuzufügen.',
  'components.follow.noItems.button': 'Künstler durchsuchen',
  'components.me.artist.title': 'Meine Künstler',
  'components.me.privacy.title': 'Meine Datenschutzeinstellungen',
  'components.me.profile.title': 'Mein Profil',
  'components.me.profile.updated': 'Ihr Profil wurde erfolgreich aktualisiert.',
  'components.me.account.title': 'Mein Konto',
  'components.me.account.unlink': 'Aufheben von {network}',
  'components.me.account.deactivate.title': 'Deaktivieren Sie mein Konto',
  'components.me.account.deactivate.description1':
    'Durch die Deaktivierung Ihres Kontos wird Ihr Profil deaktiviert und Ihre persönlichen Daten werden von Rise Art entfernt. Einige Informationen können weiterhin für andere sichtbar sein, z. B. die von Ihnen erstellten Veranstaltungen.',
  'components.me.account.deactivate.description2':
    'Sie können Ihr Konto <strong>reaktivieren</strong>, indem Sie einfach zu Rise Art zurückkehren und sich bei Ihrem Konto anmelden. Wir hoffen, Sie bald wiederzusehen.',
  'components.me.account.deactivate.button': 'Deaktivieren Sie mein Konto',
  'components.me.account.deactivate.confirmation':
    'Sind Sie sicher, dass Sie Ihr Konto deaktivieren möchten?',
  'components.me.account.facebook.label': 'Facebook',
  'components.me.account.facebook.descriptionUnlinked':
    'Verknüpfen Sie Ihr Facebook-Konto und verwenden Sie es, um sich bei Rise Art anzumelden.',
  'components.me.account.facebook.descriptionLinked':
    'Sie können Ihr Facebook-Konto verwenden, um sich bei Rise Art anzumelden.',
  'components.me.account.google.label': 'Google',
  'components.me.account.google.descriptionUnlinked':
    'Verknüpfen Sie Ihr Google-Konto und verwenden Sie es, um sich bei Rise Art anzumelden.',
  'components.me.account.google.descriptionLinked':
    'Sie können Ihr Google-Konto verwenden, um sich bei Rise Art anzumelden.',
  'components.me.account.notLinked': 'Nicht verknüpft',
  'components.me.account.linked': 'Verknüpft',
  'components.me.account.emailLabel': 'E-Mail',
  'components.me.account.setPassword': 'Passwort festlegen',
  'components.me.account.changeEmail': 'E-Mail ändern',
  'components.me.account.changePassword': 'Passwort ändern',
  'components.me.account.unlinkTitle': 'Entkoppeln Sie Ihr {network} Konto',
  'components.me.account.unlinkConfirmationBody':
    'Sind Sie sicher, dass Sie die Verknüpfung Ihres Kontos aufheben möchten?',
  'components.me.account.createAccountDescription':
    'Bevor Sie die Verknüpfung Ihres Kontos aufheben, müssen Sie ein Passwort erstellen. Bitte legen Sie ein Passwort fest, mit dem Sie sich mit Ihrer E-Mail-Adresse anmelden können.',
  'components.me.offer.title': 'Kunstwerk {sku}',
  'components.me.offer.listTitle': 'Meine Angebote',
  'components.me.offer.offerTitle': 'Ihr Angebot für "{title}"',
  'components.me.offer.created': 'Erstellt am {date}',
  'components.me.offer.expires': 'Läuft ab am {date}',
  'components.me.offer.viewDetails': 'Einzelheiten',
  'components.me.offer.percentDiscount': '{discount} rabatt',
  'components.me.offer.status.pending': 'Ausstehend',
  'components.me.offer.status.active': 'Akzeptiert',
  'components.me.offer.status.inactive': 'Inaktiv',
  'components.me.offer.state.declined': 'Abgelehnt',
  'components.me.offer.state.expired': 'Abgelaufen',
  'components.me.offer.state.unanswered': 'Unbeantwortete Fragen',
  'components.me.offer.state.used': 'Gebraucht',
  'components.me.offer.state.canceled': 'Abgesagt',
  'components.me.offer.state.error': 'Inaktiv',
  'components.me.offer.accept.action': 'Akzeptieren',
  'components.me.offer.accept.title': 'Bestätigen Sie das Angebot anzunehmen',
  'components.me.offer.accept.description':
    'Sind Sie sicher, dass Sie das Angebot annehmen wollen?',
  'components.me.offer.cancel.action': 'Abbrechen',
  'components.me.offer.cancel.title': 'Bestätigen Sie Angebot stornieren',
  'components.me.offer.cancel.description':
    'Sind Sie sicher, dass Sie das Angebot stornieren möchten?',
  'components.me.offer.noItems.title': 'Sie haben zur Zeit keine Angebote',
  'components.me.offer.noItems.description':
    'Auf der Detailseite der Kunstwerke können Sie nach Kunstwerken suchen und Angebote einstellen.',
  'components.me.offer.noItems.button': 'Jetzt in der Kunst',
  'components.search.artistTitleBasic': 'Künstler',
  'components.search.artistTitle': 'Künstler ({count})',
  'components.search.artworkTitleBasic': 'Kunstwerke',
  'components.search.artworkTitle': 'Kunstwerke ({count})',
  'components.search.searchByDomainButton': 'Alle anzeigen',
  'components.search.noSearchQuery':
    'Bitte geben Sie oben eine Suchanfrage ein, um Ihre Suchergebnisse zu sehen.',
  'components.search.noArtists':
    'Es wurden keine Künstler für Ihre Anfrage gefunden. Bitte verfeinern Sie Ihre Suche.',
  'components.search.noArtworks':
    'Es wurden keine Kunstwerke für Ihre Anfrage gefunden. Bitte verfeinern Sie Ihre Suche.',
  'forms.common.close': 'Schließen',
  'forms.common.save': 'Speichern',
  'forms.common.saveChanges': 'Änderungen speichern',
  'forms.common.cancel': 'Abbrechen',
  'forms.common.reset': 'Zurücksetzen',
  'forms.common.saveSettings': 'Einstellungen speichern',
  'forms.common.loading': 'Wird geladen ...',
  'forms.common.wait': 'Bitte warten Sie ...',
  'forms.common.confirmLegal':
    'Mit dem Anklicken von „{actionLabel}“ bestätigen Sie, dass Sie die {terms} und {policy} von Rise Art gelesen und verstanden haben und ihnen zustimmen.',
  'forms.common.terms_and_conditions': 'Allgemeine Geschäftsbedingungen',
  'forms.common.privacy_policy': 'Datenschutzbestimmungen',
  'forms.common.returnPolicies': 'Informationen zum Rückgaberecht',
  'forms.common.confirmRental':
    'Ich habe die Bedingungen ({rental}) gelesen und stimme ihnen zu. Ich ermächtige Rise Art, Anweisungen an das Finanzinstitut, das meine Karte ausgestellt hat, zu senden, um in Übereinstimmung mit den Bedingungen dieser Vereinbarung Zahlungen von meinem Kartenkonto einzuziehen.',
  'forms.common.rentalAgreement': 'Mietvertrag',
  'forms.common.confirmSubscribeTitle': 'Möchten Sie (anregende) E-Mail-Updates erhalten?',
  'forms.common.confirmSubscribeText':
    'Wir erfreuen Sie mit tollen Angeboten, neuen Werken, unseren Lieblingskünstlern und aktuellen Nachrichten.',
  'forms.common.confirmSubscribeYes': 'Ja, bitte, schicken Sie sie mir.',
  'forms.common.confirmSubscribeNo':
    'Nein, ich möchte nicht über die neuesten Angebote und Nachrichten informiert werden.',
  'forms.common.validation_messages.not_valid_email':
    'Dies ist eine ungültige E-Mail-Adresse, bitte korrigieren Sie sie.',
  'forms.common.validation_messages.not_empty_email':
    'Das Feld E-Mail ist ein Pflichtfeld, es darf daher nicht leer sein.',
  'forms.common.validation_messages.not_valid_password':
    'Ungültiges Passwort. Das Passwort muss mindestens 6 Zeichen lang sein und darf keine Leerzeichen enthalten.',
  'forms.common.validation_messages.not_empty_password':
    'Ein Passwort ist zwingend erforderlich, bitte geben Sie eines an.',
  'forms.common.validation_messages.not_valid_first_name':
    'Der Vorname darf nur Buchstaben, Leerzeichen oder „-‘‘, aber keine Zahlen enthalten.',
  'forms.common.validation_messages.not_empty_first_name':
    'Der Vorname ist zwingend erforderlich, bitte geben Sie einen an.',
  'forms.common.validation_messages.not_valid_last_name':
    'Der Nachname darf nur Buchstaben, Leerzeichen oder „-‘‘, aber keine Zahlen enthalten.',
  'forms.common.validation_messages.not_empty_last_name':
    'Der Nachname ist zwingend erforderlich, bitte geben Sie einen an.',
  'forms.common.validation_messages.notValidName':
    'Der Name darf nur Buchstaben, Leerzeichen oder „-‘‘, aber keine Zahlen enthalten.',
  'forms.common.validation_messages.not_empty_subscribe':
    'Bitte wählen Sie eine der folgenden Optionen aus.',
  'forms.common.validation_messages.invalidPhone':
    'Dies ist keine gültige Telefonnummer, bitte korrigieren Sie sie.',
  'forms.common.validation_messages.notValidOffer':
    'Der Angebotsbetrag ist zu niedrig oder eine ungültige Zahl',
  'forms.common.validation_messages.notEmptySearch':
    'Das Suchfeld ist ein Pflichtfeld, bitte stellen Sie sicher, dass es ausgefüllt ist.',
  'forms.common.validation_messages.notEmptyDiscount':
    'Ein Rabattcode ist erforderlich, bitte stellen Sie sicher, dass das Feld ausgefüllt ist.',
  'forms.common.validation_messages.notEmptyPostcode':
    'Eine Postleitzahl ist zwingend erforderlich, bitte stellen Sie sicher, dass das Feld ausgefüllt ist.',
  'forms.common.validation_messages.notEmptyCity':
    'Eine Stadt ist zwingend erforderlich, bitte stellen Sie sicher, dass das Feld ausgefüllt ist.',
  'forms.common.validation_messages.notEmptyAddress':
    'Das Adressfeld ist Pflichtfeld, das ausgefüllt sein muss.',
  'forms.common.validation_messages.notEmptyCountry':
    'Das Land ist Pflichtfeld, das ausgefüllt sein muss.',
  'forms.common.validation_messages.notValidBudget':
    'Dies ist kein gültiges Budget, bitte korrigieren Sie es.',
  'forms.common.validation_messages.required': 'Dieses Feld ist erforderlich',
  'forms.common.validation_messages.requiredByCountry':
    'Dieses Feld ist für das ausgewählte Land erforderlich',
  'forms.common.validation_messages.maxPhoneLength':
    'Die Telefonnummer ist mehr als 15 Zeichen lang.',
  'forms.common.label.email': 'E-Mail',
  'forms.common.label.firstName': 'Vorname',
  'forms.common.label.lastName': 'Nachname',
  'forms.common.label.birthDate': 'Geburtsdatum',
  'forms.common.label.gender': 'Geschlecht',
  'forms.common.label.country': 'Land',
  'forms.common.label.city': 'Stadt',
  'forms.common.label.phone': 'Telefon',
  'forms.checkout.firstName': 'Vorname',
  'forms.checkout.lastName': 'Nachname',
  'forms.checkout.email': 'E-Mail',
  'forms.checkout.phone': 'Telefon',
  'forms.checkout.company': 'Unternehmen',
  'forms.checkout.addressLine1': 'Adressezeile 1',
  'forms.checkout.addressLine2': 'Adresszeile 2',
  'forms.checkout.city': 'Stadt',
  'forms.checkout.county': 'Bundesland, Landkreis oder Region',
  'forms.checkout.postcode': 'Postleitzahl/PLZ',
  'forms.checkout.country': 'Land',
  'forms.checkout.deliveryInstructions': 'Anweisungen für die Lieferung',
  'forms.checkout.cardType': 'Kartentyp',
  'forms.checkout.cardOwner': 'Name auf der Karte',
  'forms.checkout.cardNumber': 'Kartennummer',
  'forms.checkout.expirityDate': 'Gültig bis',
  'forms.checkout.expirityDatePlaceholder': 'MM/JJ',
  'forms.checkout.cvc': 'Sicherheitscode',
  'forms.checkout.startDate': 'Ausgabedatum',
  'forms.checkout.issueNumber': 'Ausgabe #',
  'forms.checkout.savedCards': 'Meine Karten',
  'forms.checkout.validation.notEmptyCardNumber': 'Kartennummer erforderlich',
  'forms.checkout.validation.notSupportedCardNumber': 'Dieser Kartentyp wird nicht unterstützt',
  'forms.checkout.validation.notCompleteCardNumber': 'Unvollständige Kartennummer',
  'forms.checkout.validation.notValidCardNumber': 'Ungültige Kartennummer',
  'forms.checkout.validation.notEmptyCardOwner': 'Der Kartenname ist ein Pflichtfeld',
  'forms.checkout.validation.notEmptyCardValidThru':
    'Das Feld „Karte gültig bis“ ist ein Pflichtfeld',
  'forms.checkout.validation.notValidCardValidThru':
    'Das Datum unter „Karte gültig bis“ muss in der Zukunft liegen',
  'forms.checkout.validation.cardStartDateNotInPast':
    'Das Ausgabedatum der Karte muss in der Vergangenheit liegen',
  'forms.checkout.validation.notEmptyCardSecurity':
    'Der Sicherheitscode der Karte ist zwingend erforderlich',
  'forms.checkout.validation.notValidCardSecurity': 'Ungültiger Sicherheitscode',
  'forms.regionalForm.localeSelectLabel': 'Sprache',
  'forms.regionalForm.currencySelectLabel': 'Preise anzeigen in',
  'forms.regionalForm.countrySelectLabel': 'Versand nach',
  'forms.regionalForm.unitsSelectLabel': 'Einheiten anzeigen',
  'forms.regionalForm.units.metric': 'Metrisch (cm, kg)',
  'forms.regionalForm.units.imperial': 'Imperiale (in, lb)',
  'forms.subscribeForm.messages.error':
    'Die Anmeldung zu unserer Mailingliste ist fehlgeschlagen. Bitte versuchen Sie es später erneut oder kontaktieren Sie uns.',
  'forms.subscribeForm.messages.warning':
    'Die von Ihnen angegebene E-Mail-Adresse ist bereits in unserer Mailingliste eingetragen.',
  'forms.subscribeForm.messages.success':
    'Vielen Dank, dass Sie sich in unsere Mailingliste eingetragen haben. Herzlich willkommen in der Rise Art-Community! Bitte überprüfen Sie Ihren Posteingang: Wir haben Ihnen zur Bestätigung Ihres Abonnements eine E-Mail geschickt. Außerdem haben wir zusätzliche nützliche Informationen für Sie beigefügt. Wenn Sie die E-Mail nicht innerhalb der nächsten Minuten erhalten, überprüfen Sie bitte Ihren Spam- und Junk-Ordner.',
  'forms.subscribeForm.messages.successTitle': 'Hat geklappt!',
  'forms.subscribeForm.placeholder': 'E-Mail-Adresse',
  'forms.subscribeForm.submitButtonText': 'Beitreten',
  'forms.subscribeForm.subscribeTerms':
    'Mit der Angabe Ihrer E-Mail-Adresse erklären Sie sich damit einverstanden, (anregende) E-Mail-Updates zu erhalten, einschließlich toller Angebote, neuer Werke und künstlerischer Neuigkeiten. Den Newsletter können Sie jederzeit wieder abbestellen.',
  'forms.searchForm.placeholder': 'Suchen',
  'forms.searchForm.submit': 'Auf der Website suchen',
  'forms.searchForm.suggestionTitle': 'Suchvorschläge',
  'forms.searchForm.clear': 'Suche löschen',
  'forms.auth_form.placeholder.firstName': 'Vorname',
  'forms.auth_form.placeholder.lastName': 'Nachname',
  'forms.auth_form.placeholder.email': 'E-Mail-Adresse',
  'forms.auth_form.placeholder.password': 'Passwort',
  'forms.auth_form.placeholder.newPassword': 'Neues Passwort',
  'forms.auth_form.placeholder.show': 'Passwort anzeigen',
  'forms.auth_form.register_button_text': 'Registrieren',
  'forms.auth_form.login_button_text': 'Anmelden',
  'forms.auth_form.register_text': 'Sie sind bereits Mitglied?',
  'forms.auth_form.forget_password_text': 'Haben Sie Ihr Passwort vergessen?',
  'forms.auth_form.register_error':
    'Die Registrierung auf unserer Website ist fehlgeschlagen. Bitte versuchen Sie es später erneut oder kontaktieren Sie uns.',
  'forms.auth_form.login_error':
    'Die Anmeldung auf unserer Website ist fehlgeschlagen. Bitte versuchen Sie es später erneut oder kontaktieren Sie uns.',
  'forms.auth_form.show_password_text': 'ANZEIGEN',
  'forms.auth_form.hide_password_text': 'VERBERGEN',
  'forms.auth_form.sign_up_text': 'Oder erstellen Sie ein Rise Art-Konto mit Ihrer E-Mail-Adresse',
  'forms.auth_form.sign_in_text': 'Oder melden Sie sich mit Ihrem Rise Art-Konto an.',
  'forms.auth_form.forgotPassword.title': 'Passwort vergessen',
  'forms.auth_form.forgotPassword.subtitle':
    'Geben Sie die E-Mail-Adresse ein, die Sie bei Rise Art registriert haben, und wir senden Ihnen Anweisungen zum Zurücksetzen Ihres Passworts per E-Mail.',
  'forms.auth_form.forgotPassword.button': 'Passwort erhalten',
  'forms.auth_form.forgotPassword.success':
    'Wir haben Ihnen eine E-Mail an die angegebene E-Mail-Adresse gesendet. Bitte überprüfen Sie Ihr E-Mail-Konto und folgen Sie den Anweisungen, um Ihr Passwort zurückzusetzen',
  'forms.auth_form.forgotPassword.successButton': 'Zur Startseite gehen',
  'forms.auth_form.resetPassword.title': 'Passwort ändern',
  'forms.auth_form.resetPassword.subtitle':
    'Geben Sie unten Ihre E-Mail-Adresse und ein neues Passwort ein, um Ihr Passwort zu ändern.',
  'forms.auth_form.resetPassword.button': 'Passwort ändern',
  'forms.single_field_form.submit_text': 'Beitreten',
  'forms.single_field_form.label_text': 'Auf dem Laufenden bleiben',
  'forms.single_field_form.label_form_field_placeholder': 'E-Mail-Adresse',
  'forms.cmsEnquire.successTitle': 'Vielen Dank',
  'forms.cmsEnquire.success': 'Vielen Dank für die Übermittlung Ihrer Anfrage.',
  'forms.emailEnquire.overlayTitle': 'Sprechen Sie mit einem Berater',
  'forms.emailEnquire.send': 'Senden',
  'forms.emailEnquire.download': 'Jetzt herunterladen',
  'forms.emailEnquire.placeholder.firstName': 'Vorname',
  'forms.emailEnquire.placeholder.lastName': 'Nachname',
  'forms.emailEnquire.placeholder.name': 'Name',
  'forms.emailEnquire.placeholder.email': 'E-Mail',
  'forms.emailEnquire.placeholder.phone': 'Telefonnummer',
  'forms.emailEnquire.placeholder.subject': 'Betreff',
  'forms.emailEnquire.placeholder.message': 'Nachricht',
  'forms.emailEnquire.placeholder.company': 'Unternehmen',
  'forms.emailEnquire.placeholder.offer': 'Ihr Angebot',
  'forms.emailEnquire.placeholder.budget': 'Budget',
  'forms.emailEnquire.placeholder.moreDetails': 'Mehr Details',
  'forms.emailEnquire.placeholder.country': 'Land',
  'forms.emailEnquire.placeholder.space': 'Platz',
  'forms.emailEnquire.placeholder.productPrice': 'Listenpreis',
  'forms.emailEnquire.subject.arrangeViewing': 'Eine Besichtigung vereinbaren',
  'forms.emailEnquire.subject.additionalImages': 'Mehr Bilder von Kunstwerken',
  'forms.emailEnquire.subject.pricing': 'Preis und Verfügbarkeit',
  'forms.emailEnquire.subject.shipping': 'Versand',
  'forms.emailEnquire.subject.sellArtwork': 'Ich möchte dieses Kunstwerk verkaufen',
  'forms.emailEnquire.subject.other': 'Andere',
  'forms.emailEnquire.space.livingRoom': 'Wohnzimmer',
  'forms.emailEnquire.space.bedroom': 'Schlafzimmer',
  'forms.emailEnquire.space.kidsRoom': 'Kinderzimmer',
  'forms.emailEnquire.space.kitchen': 'Küche',
  'forms.emailEnquire.space.other': 'Andere',
  'forms.emailEnquire.description':
    'Füllen Sie das Formular aus, damit einer unserer Berater Sie kontaktieren kann.',
  'forms.emailEnquire.advisorsContactDescription':
    'Sie können uns auch unter <strong>{advisorPhone}</strong> anrufen oder direkt mit uns chatten, indem Sie das Chat-Symbol in der unteren rechten Seitenecke verwenden.',
  'forms.emailEnquire.success':
    'Vielen Dank für die Übermittlung Ihrer Anfrage. Einer unserer Berater wird sich in Kürze mit Ihnen in Verbindung setzen. ',
  'forms.emailEnquire.successOffer':
    'Vielen Dank für die Einreichung Ihres Angebots. Wir haben den Künstler benachrichtigt und werden uns in Kürze mit Ihnen in Verbindung setzen.',
  'forms.emailEnquire.advisorInfo':
    '<strong>{advisorName}</strong>, unser Kunstberater auf Abruf, wird sich schnellstmöglichst bei Ihnen melden.',
  'forms.enquireOffer.title': 'Angebot abgeben',
  'forms.enquireOffer.description':
    'Füllen Sie das Formular aus, um ein Angebot für <strong>{artworkName}</strong> von <strong>{artistName}</strong> abzugeben.',
  'forms.enquireOffer.offer': 'Angebot',
  'forms.enquireOffer.submitBtn': 'Angebot abgeben',
  'forms.enquireArtistCommission.title': 'Ein Kunstwerk in Auftrag geben',
  'forms.enquireArtistCommission.description':
    'Füllen Sie das Formular aus, um eine Kommissionsanfrage für <strong>{artistName}</strong> zu stellen.',
  'forms.enquireArtistCommission.submitBtn': 'Anfrage versenden',
  'forms.enquireArtistCommission.extra.moreDetails':
    'Haben Sie bereits eine erste Vorstellung über die Größe, das Medium und die Farben oder können Sie uns Referenzkunstwerke nennen? Lassen Sie es uns hier wissen.',
  'forms.cart.discountCodePlaceholder': 'Rabattcode eingeben',
  'forms.cart.voucherCode': 'Gutschein-Code',
  'forms.cart.voucherCodeHint':
    'Geben Sie den 16-stelligen Code ein (einschließlich Bindestrichen).',
  'forms.cart.securityCode': 'Sicherheitscode',
  'forms.cart.securityCodeHint':
    'Wenn Ihr Gutschein einen Sicherheitscode enthält, geben Sie diesen bitte ein, um Ihr Guthaben einzulösen.',
  'forms.cart.redeemGiftCard': 'Geschenkgutschein einlösen',
  'forms.profile.privacy.save': 'Änderungen speichern',
  'forms.profile.privacy.fields.newsletter': 'RA-Newsletter erhalten',
  'forms.profile.privacy.fields.activity_update': 'Infos zu Aktivitäten erhalten',
  'forms.profile.privacy.fields.activity_weekly': 'Wochenzusammenfassung der Aktivitäten erhalten',
  'forms.profile.privacy.fields.product_update': 'Updates zu Kunstwerken erhalten',
  'forms.profile.privacy.fields.general': 'Allgemeine E-Mails erhalten',
  'forms.profile.privacy.hints.newsletter':
    'Wöchentlichen Rise Art-Newsletter abonnieren, um über Neuigkeiten und aktuelle Events informiert zu werden',
  'forms.profile.privacy.hints.activity_update':
    'Benachrichtigungen über neue Aktivitäten zu Ihrem Profil abonnieren (z. B. neue Follower, neue User, denen Ihre Kunst gefällt usw.)',
  'forms.profile.privacy.hints.activity_weekly':
    'Wöchentliche Zusammenfassung über die letzten Aktivitäten zu Ihrem Profil abonnieren',
  'forms.profile.privacy.hints.product_update':
    'Updates zu Werken von Künstlern abonnieren, für die Sie sich interessieren',
  'forms.profile.privacy.hints.general':
    'Allgemeine Benachrichtigungen erhalten (z. B. Empfehlungen, Erinnerungen, usw.)',
  'forms.profile.account.newEmail': 'Neue E-Mail',
  'forms.search.domain.all': 'Alle',
  'forms.search.domain.art': 'Kunst',
  'forms.search.domain.artist': 'Künstler',
  'forms.search.domain.collection': 'Sammlungen',
  'forms.search.yourSearchResults': 'Ihre Suchergebnisse für:',
  'forms.search.yourArtworkSearchResults': 'Ihre KUNSTWERK-SUCHERGEBNISSE für:',
  'forms.search.yourArtistSearchResults': 'Ihre KÜNSTLER-SUCHERGEBNISSE für:',
  'forms.search.visual.refresh': 'Aktualisieren',
  'forms.search.visual.restart': 'Neustart',
  'forms.search.visual.yourSearchResults': 'Ihre visuell ähnlichen Ergebnisse für:',
  'forms.search.visual.help':
    'Klicken Sie auf das Symbol für visuell ähnliche Kunstwerke, um Ihre Suche zu verfeinern',
  'forms.search.visual.searchHint':
    'Sie wissen nicht, wonach Sie suchen sollen? Versuchen Sie unsere <a>visuelle Suche</a>',
  'forms.search.visual.searchHintShort':
    'Sie wissen nicht, wonach Sie suchen sollen? Versuchen Sie unsere <a>visuelle Suche</a>',
  'forms.search.visual.searchHintButton': 'Testen Sie unsere <icon></icon> visuelle Suche',
  'forms.search.visual.actions.backToResult': 'Zurück zu diesem Ergebnis',
  'forms.search.visual.actions.seeArtwork': 'Siehe Details zum Kunstwerk',
  'forms.search.visual.actions.addToBasket': 'In den Warenkorb legen',
  'layouts.logo.title': 'Rise Art',
  'layouts.header.search.placeholder': 'Künstler, Kategorie, Thema...',
  'layouts.header.regionalOverlayTitle': 'Regionale Einstellungen',
  'layouts.header.topmenu.sellerConsole': 'Verkäufer-Konsole',
  'layouts.header.topmenu.myPrivacy': 'Mein Datenschutz',
  'layouts.header.topmenu.myOffers': 'Meine Angebote',
  'layouts.header.topmenu.login': 'Anmeldung',
  'layouts.header.topmenu.cart': 'Warenkorb',
  'layouts.header.topmenu.signIn': 'Anmelden',
  'layouts.header.topmenu.signUp': 'Registrieren',
  'layouts.header.topmenu.signOut': 'Abmelden',
  'layouts.header.topmenu.artistSignUp': 'Registrierung für Künstler',
  'layouts.header.topmenu.myRiseart': 'Mein Rise Art',
  'layouts.header.topmenu.mySettings': 'Meine Einstellungen',
  'layouts.header.topmenu.inviteFriends': 'Freunde einladen',
  'layouts.header.topmenu.myCredit': 'Mein Guthaben',
  'layouts.header.topmenu.giftCard': 'Geschenkgutschein',
  'layouts.header.topmenu.basket': 'Warenkorb',
  'layouts.header.topmenu.wishlist': 'Wunschzettel',
  'layouts.header.topmenu.myWishlist': 'Mein Wunschzettel',
  'layouts.header.topmenu.myArtists': 'Meine Künstler',
  'layouts.header.topmenu.profile': 'Mein Profil',
  'layouts.header.topmenu.account': 'Mein Konto',
  'layouts.header.topmenu.avatarLabel': 'Profil',
  'layouts.header.topmenu.recentArt': 'Meine aktuelle Kunst',
  'layouts.footer.title': 'Auf dem Laufenden bleiben',
  'layouts.footer.subscribe.title': 'Tragen Sie sich in unsere Mailingliste ein.',
  'layouts.footer.subscribe.sub_title':
    'Erhalten Sie als Erster unsere neuesten Nachrichten, Updates und Angebote!',
  'layouts.footer.zeroFinance': '0 % FINANZIERUNG',
  'layouts.footer.social.title': 'VERBINDEN MIT',
  'layouts.footer.social.facebook': 'Liken Sie uns auf Facebook',
  'layouts.footer.social.twitter': 'Folgen Sie uns auf Twitter',
  'layouts.footer.social.pinterest': 'Folgen Sie uns auf Pinterest',
  'layouts.footer.social.instagram': 'Folgen Sie uns auf Instagram',
  'layouts.footer.bottom.copyright': 'Urheberrecht',
  'layouts.footer.bottom.riseart': 'Rise Art Ltd.',
  'layouts.footer.bottom.company': 'Eingetragenes britisches Unternehmen 07099261',
  'layouts.footer.bottom.address': '41 Great Portland Street, London W1W 7LA, UK',
  'messages.notifications.titles.error': 'Fehler',
  'messages.notifications.titles.warning': 'Warnung',
  'messages.notifications.titles.info': 'Achtung',
  'messages.notifications.titles.success': 'Hat geklappt!',
  'messages.application.cookie.message.short':
    'Cookies helfen uns bei der Bereitstellung unserer Dienste. Durch die Nutzung der Rise Art-Website erklären Sie sich mit der Verwendung von Cookies einverstanden.',
  'messages.application.cookie.message.long':
    'Rise Art eigene Cookies verwendet, um seine Dienste bereitzustellen, deren Qualität zu verbessern und Datenverkehr zu analysieren. Sofern Sie hier zustimmen, werden Cookies auch bei der Einblendung von Werbung verwendet sowie zur Personalisierung der angezeigten Inhalte und Werbeanzeigen.',
  'messages.application.cookie.reject': 'Nein danke',
  'messages.application.cookie.accept': 'Stimme zu',
  'messages.application.browser_support':
    'Ihr Browser ist mit dieser Website nicht kompatibel, einige Funktionen sind möglicherweise nicht nutzbar.',
  'messages.application.artfetch':
    'Artfetch wurde von Rise Art übernommen – zeitgenössische Kunst, von Experten handverlesen.',
  'messages.application.kazoart':
    'KAZoART ist jetzt Rise Art, kaufen Sie zeitgenössische Kunst, handverlesen von Experten. <a>Mehr Erfahren</a>',
  'messages.application.kinaesthesia':
    'JETZT LIVE: Besuchen Sie unsere Online-Ausstellung<span class="ra-show-for-medium"> - <a href="/exhibition/kinaesthesia" title="KINAESTHESIA: Kunst in Bewegung" class="ra-link ra-link-default">KINAESTHESIA: Kunst in Bewegung</a></span><div class="ra-show-for-small-only"><a href="/exhibition/kinaesthesia" title="KINAESTHESIA: Kunst in Bewegung" class="ra-link ra-link-default">KINAESTHESIA: Kunst in Bewegung</a></div>',
  'messages.application.error404':
    'Die von Ihnen aufgerufene Webseite ist umgezogen oder wurde gelöscht.',
  'messages.application.freeShipping.line1': 'KOSTENLOSER VERSAND',
  'messages.application.freeShipping.line2':
    'für Bestellungen {currencySign}250 + | Gültig bis 13/08, Mitternacht GMT',
  'messages.application.freeShipping.line2Truncated':
    'ab {currencySign}250 +<div>endet am 13/08, Mitternacht GMT</div>',
  'messages.application.christmasDelivery.line1':
    '<a href="/de/weihnachten" title="SCHENKEN SIE KUNST" class="ra-link ra-link-strong">SCHENKEN SIE KUNST</a>',
  'messages.application.christmasDelivery.line2':
    '<strong>Wünschen Sie eine rechtzeitige Lieferung zu Weihnachten?</strong> <a href="https://beistand.riseart.com/article/261-weihnachten" target="_blank" title="Erfahren Sie mehr" class="ra-link ra-link-default">Erfahren Sie mehr.</a>',
  'messages.application.christmasDelivery.line2Truncated':
    '<strong>Wünschen Sie eine rechtzeitige Lieferung zu Weihnachten?</strong> <a href="https://beistand.riseart.com/article/261-weihnachten" target="_blank" title="Erfahren Sie mehr" class="ra-link ra-link-default">Erfahren Sie mehr.</a>',
  'messages.application.christmasGift.line1': 'Verschenken Sie Kunst mit einem ',
  'messages.application.christmasGift.line2':
    '<a href="/de/geschenkkarten" title="Rise Art Geschenkgutschein" class="ra-link ra-link-default">RISE ART GESCHENKGUTSCHEIN</a>',
  'options.layout.sq': 'Rechteckig',
  'options.layout.fl': 'Flüssig',
  'options.sort.art.ontherise': 'Im Trend',
  'options.sort.art.alltimetop': 'Am beliebtesten',
  'options.sort.art.latest': 'Neuerscheinungen',
  'options.sort.art.price': 'Preis',
  'options.sort.art.artist': 'Alle Formen der Kunst',
  'options.sort.art.default': 'Alle Formen der Kunst',
  'options.sort.art.relevance': 'Relevanz',
  'options.sort.artist.featured': 'Ausgewählte Künstler',
  'options.sort.artist.latest': 'Neueste Künstler',
  'options.sort.artist.popularity': 'Beliebte Künstler',
  'options.sort.artist.alpha': 'A - Z',
  'options.sort.artist.relevance': 'Relevanz',
  'errors.default.title': 'Fehler',
  'errors.default.details':
    'Es gab einen Fehler in der Anwendung. Bitte laden Sie die Seite neu und kontaktieren Sie uns, wenn dieser Fehler weiterhin auftritt.',
  'errors.pageNotFound.title': 'Seite nicht gefunden',
  'errors.pageNotFound.details': 'Die angeforderte URL wurde auf diesem Server nicht gefunden.',
  'errors.pageNotFound.htmlDetails':
    'Die angeforderte URL {notFoundUrl} wurde auf diesem Server nicht gefunden.',
  'errors.forbiddenException.title': 'Verbotene',
  'errors.forbiddenException.details':
    'Sie verfügen nicht über die erforderlichen Zugriffsrechte, um auf diese Ressource zuzugreifen.',
  'errors.networkError.title': 'Netzwerkfehler',
  'errors.networkError.details': 'Die Verbindung ist nur eingeschränkt oder gar nicht möglich.',
  'errors.timeoutError.title': 'Timeout-Fehler',
  'errors.timeoutError.details':
    'Es dauert zu lange, einige Ressourcen von unseren Servern zu laden. Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut.',
  'errors.invalidToken.title': 'Fehler - Autorisierung ungültig',
  'errors.invalidToken.details':
    'Ihre Sitzung ist abgelaufen. Bitte aktualisieren Sie die Seite, um die Sitzung zu verlängern.',
  'errors.apiServerError.title': 'Server-Fehler',
  'errors.apiServerError.details':
    'Wir arbeiten unter Hochdruck daran, das Problem zu beheben. Vielen Dank für Ihre Geduld.',
  'errors.notFoundVisitor.title': 'Keine Identität gefunden',
  'errors.notFoundVisitor.details':
    'Beim Laden von Daten während der Initialisierung der Anwendung gab es einige Probleme. Einige Funktionen funktionieren möglicherweise nicht richtig. Bitte aktualisieren Sie Ihre Anwendung!',
  'meta.home.pageTitle':
    'Zeitgenössische Kunst zum Kauf. Kunst online kaufen. Online-Kunstgalerie.',
  'meta.home.title': 'Kunst online kaufen | Zeitgenössische Kunst zum Kauf | Rise Art',
  'meta.home.keywords':
    'contemporary art, contemporary arts, contemporary abstract art, art dealers, art for sale, art websites, buy art, buy art online, original art, original art for sale, buy original art, art original, canvas wall art, fine art, fine art for sale, buy fine art online, framed art, modern art, modern art for sale, buy modern art online, online art, online art gallery, art advisors, art advisory service, art dealer, online art dealer, curated art, art curation, interior design help, art for the office, office art',
  'meta.home.description':
    'Entdecken, besitzen und sammeln Sie zeitgenössische Kunst von interessanten Künstlern aus der ganzen Welt. Entdecken Sie unsere Online-Kunstgalerie mit käuflichen Kunstwerken, die von unseren Kuratoren fachmännisch überprüft wurden. Kaufen oder mieten Sie vertrauensvoll Kunst online mit kostenloser Kunstberatung.',
  'meta.art.pageTitle': 'Zeitgenössische Kunst zum Kauf. Kunst online kaufen. Online-Kunstgalerie.',
  'meta.art.title': 'Kunst suchen | Zeitgenössische Kunst online kaufen | Rise Art',
  'meta.art.keywords':
    'contemporary art, contemporary arts, contemporary abstract art, art dealers, art for sale, art websites, buy art, buy art online, original art, original art for sale, buy original art, art original, canvas wall art, fine art, fine art for sale, buy fine art online, framed art, modern art, modern art for sale, buy modern art online, online art, online art gallery, art advisors, art advisory service, art dealer, online art dealer, curated art, art curation, interior design help, art for the office, office art',
  'meta.art.description':
    'Sehen Sie sich zeitgenössische Kunst von interessanten Künstlern aus aller Welt an. Entdecken Sie unsere Online-Kunstgalerie mit Kunstwerken zum Kauf, darunter Gemälde, Kunstdrucke, Skulpturen, Fotografien und Zeichnungen in vielfältigen Stilen von abstraktem Expressionismus bis Pop-Art. Kunst vertrauensvoll online kaufen oder mieten.',
  'meta.artDetails.pageTitle':
    'Zeitgenössische Kunst zum Kauf. Kunst online kaufen. Online-Kunstgalerie.',
  'meta.artDetails.title': 'Zeitgenössische Kunst online kaufen | Rise Art',
  'meta.artDetails.keywords':
    'buy art online, original art, art, online art gallery, artists, contemporary art, art advisory, art for sale',
  'meta.artDetails.description':
    'Entdecken, besitzen und sammeln Sie Originalkunst von interessanten Künstlern aus aller Welt. Wir sind eine Online-Kunstgalerie, d. h. jedes Kunstwerk, das zum Verkauf steht, wurde von unseren Kuratoren fachmännisch geprüft. Kaufen Sie vertrauensvoll Kunst online, mit kostenloser Kunstberatung und 14 Tagen kostenlosem Rückgaberecht.',
  'meta.artDetails.dynamicMetaTitle': '{name} von {artist} | Kunst online kaufen | Rise Art',
  'meta.artDetails.dynamicPageTitle': 'Kaufen Sie {name} von {artist}. Online-Kunstgalerie.',
  'meta.artDetails.dynamicMetaDescription':
    'Finden Sie {name} von {artist} online. Wählen Sie aus Tausenden zeitgenössischen Kunstwerken von interessanten Künstlern aus, die von den Kuratoren von Rise Art fachmännisch ausgewählt wurden. Kaufen Sie vertrauensvoll Kunst online mit kostenloser Kunstberatung.',
  'meta.artist.pageTitle':
    'Werke von zeitgenössischen Künstlern zum Kauf. Kunst online kaufen. Online-Kunstgalerie.',
  'meta.artist.title': 'Künstler suchen | Zeitgenössische Kunst online kaufen | Rise Art',
  'meta.artist.keywords':
    'contemporary art, contemporary arts, contemporary abstract art, art dealers, art for sale, art websites, buy art, buy art online, original art, original art for sale, buy original art, art original, canvas wall art, fine art, fine art for sale, buy fine art online, framed art, modern art, modern art for sale, buy modern art online, online art, online art gallery, art advisors, art advisory service, art dealer, online art dealer, curated art, art curation, interior design help, art for the office, office art',
  'meta.artist.description':
    'Sehen Sie sich zeitgenössische Kunst von interessanten Künstlern aus aller Welt an. Entdecken Sie unsere Online-Kunstgalerie mit Kunstwerken zum Kauf, darunter Gemälde, Kunstdrucke, Skulpturen, Fotografien und Zeichnungen in vielfältigen Stilen von abstraktem Expressionismus bis Pop-Art. Kunst vertrauensvoll online kaufen oder mieten.',
  'meta.artistProfile.dynamicMetaTitle':
    '{name}, Künstler | Zeitgenössische Kunst zum Kauf | Rise Art',
  'meta.artistProfile.dynamicMetaDescription':
    'Sehen Sie sich unsere Auswahl an käuflich zu erwerbenden Kunstwerken von {name} an. Bei Rise Art kaufen Sie online fachmännisch kuratierte, zeitgenössische Kunst einer ausgewählten Gruppe talentierter Künstler.',
  'meta.artistProfile.dynamicMetaKeywords':
    '{name}, Künstler, berühmte Künstler, prominente Künstler, Kunst online kaufen, erschwingliche Kunst, Originalkunst, Kunst, einzigartige Geschenke, Kunstdruke in limitierter Auflage, Kunst mieten, Künstler, zeitgenössische Kunst, Museen',
  'meta.artistArt.dynamicMetaTitle':
    'Kunstwerke von {name} | Zeitgenössische Kunst zum Kauf | Rise Art',
  'meta.artistArt.dynamicMetaDescription':
    'Durchsuchen Sie unsere Auswahl an käuflichen Kunstwerken des Künstler/der Künstlerin {name}. Kaufen Sie zeitgenössische Kunst online von {name} bei Rise Art.',
  'meta.artistArt.dynamicMetaKeywords':
    '{name}, Künstler, berühmte Künstler, prominente Künstler, Kunst online kaufen, erschwingliche Kunst, Originalkunst, Kunst, einzigartige Geschenke, Kunstdruke in limitierter Auflage, Kunst mieten, Künstler, zeitgenössische Kunst, Museen',
  'meta.artistCv.dynamicMetaTitle': 'Lebenslauf von {name} | Rise Art',
  'meta.artistStudio.dynamicMetaTitle': 'Im Atelier mit {name} | Rise Art',
  'meta.artistNews.dynamicMetaTitle': 'Neuigkeiten & Ausstellungen von {name} | Rise Art',
  'meta.activity.pageTitle': 'Neueste Aktivität | Rise Art',
  'meta.activity.title': 'Neueste Aktivität | Rise Art',
  'meta.activity.keywords':
    'art, limited edition prints, posters, artist, emerging artist, emerging art, discover art, purchase art, buy art, original art, social, artist community, art blog, select artists',
  'meta.activity.description':
    'Rise Art ist eine Online-Community, in der jeder einzigartige Kunstwerke von aufstrebenden Talenten aus aller Welt entdecken, teilen und kaufen kann. Rise Art ermöglicht es den Nutzern, online direkt mit den Künstlern in Kontakt zu treten und mit ihren Werken zu interagieren. Wir sind eine exklusive Galerie, die kuratierte Werke zum Kauf anbietet.',
  'meta.guides.pageTitle':
    'Kunstleitfäden | Kunstströmungen, Kunststyling, ausgewählte Künstler, Anleitungen | Rise Art',
  'meta.guides.title':
    'Kunstleitfäden | Kunstströmungen, Kunststyling, ausgewählte Künstler, Anleitungen | Rise Art',
  'meta.guides.keywords':
    'art guides, how to, art styling, featured artists, contemporary art, art movements, art history',
  'meta.guides.description':
    'Hier finden Sie alles über Kunststyling, ausgewählte Künstler, wichtige Kunstströmungen sowie ultimative Anleitungen. Unsere Kunstleitfäden decken eine Reihe von Themen ab und bieten hilfreiche Informationen zu allen Bereichen, vom Arrangieren von Kunst bis hin zu Informationen über die Geschichte von Kunstströmungen.',
  'meta.guidesCategory.pageTitle':
    'Kunstleitfäden | Kunstströmungen, Kunststyling, ausgewählte Künstler, Anleitungen | Rise Art',
  'meta.guidesCategory.title':
    'Kunstleitfäden | Kunstströmungen, Kunststyling, ausgewählte Künstler, Anleitungen | Rise Art',
  'meta.guidesCategory.keywords':
    'art guides, how to, art styling, featured artists, contemporary art, art movements, art history',
  'meta.guidesCategory.description':
    'Hier finden Sie alles über Kunststyling, ausgewählte Künstler, wichtige Kunstströmungen sowie ultimative Anleitungen. Unsere Kunstleitfäden decken eine Reihe von Themen ab und bieten hilfreiche Informationen zu allen Bereichen, vom Arrangieren von Kunst bis hin zu Informationen über die Geschichte von Kunstströmungen.',
  'meta.guidesCategory.dynamicMetaTitle':
    '{title} | Kunstströmungen, Kunststyling, ausgewählte Künstler, Anleitungen | Rise Art',
  'meta.guidesCategory.dynamicMetaKeywords':
    '{title}, Kunstleitfäden, Anleitungen, Kunststyling, ausgewählte Künstler, zeitgenössische Kunst, Kunstströmungen, Kunstgeschichte',
  'meta.guidesCategory.dynamicMetaDescription':
    'In unseren Kunstleitfäden finden Sie alles, was Sie über {title} wissen müssen. Die Leitfäden decken eine Reihe von Themen ab und bieten hilfreiche Informationen, von der Auswahl von Kunst bis hin zu weiteren Informationen über {title}.',
  'meta.guidesArticle.title':
    'Kunstleitfäden | Kunstströmungen, Kunststyling, ausgewählte Künstler, Anleitungen | Rise Art',
  'meta.guidesArticle.keywords':
    'art guides, how to, art styling, featured artists, contemporary art, art movements, art history',
  'meta.guidesArticle.description':
    'Hier finden Sie alles über Kunststyling, ausgewählte Künstler, wichtige Kunstströmungen sowie ultimative Anleitungen. Unsere Kunstleitfäden decken eine Reihe von Themen ab und bieten hilfreiche Informationen zu allen Bereichen, vom Arrangieren von Kunst bis hin zur Geschichte der einzelnen Kunstbewegungen',
  'meta.guidesArticle.dynamicMetaTitle': '{title} | Rise Art',
  'meta.guidesArticle.dynamicMetaKeywords':
    '{title}, {tags}, Kunstleitfäden, Anleitungen, Kunststyling, ausgewählte Künstler, zeitgenössische Kunst, Kunstströmungen, Kunstgeschichte',
  'meta.guidesArticle.dynamicMetaDescription':
    'In unseren Kunstleitfäden finden Sie alles, was Sie über {title} wissen müssen. Die Leitfäden decken eine Reihe von Themen ab und bieten hilfreiche Informationen, von der Auswahl von Kunst bis hin zu weiteren Informationen über {title}.',
  'meta.blog.pageTitle':
    'Kunst-Blog | Nachrichten, Neuerscheinungen, Kunstveranstaltungen | Rise Art',
  'meta.blog.title': 'Kunst-Blog | Nachrichten, Neuerscheinungen, Kunstveranstaltungen | Rise Art',
  'meta.blog.keywords':
    'art blog, art news, art events, online art gallery, contemporary art, art history, art movements',
  'meta.blog.description':
    'Entdecken Sie die neuesten Kunstnachrichten, aktuelle Neuerscheinungen und Veranstaltungen. Ob Sie sich nun über Neuigkeiten aus der Kunstwelt auf dem Laufenden halten wollen, mehr Einblick in die Kunstgeschichte gewinnen möchten oder sich für die wichtigsten Kunstströmungen der Szene interessieren – im Rise Art Blog finden Sie alles, was Sie über zeitgenössische Kunst wissen müssen.',
  'meta.blogCategory.pageTitle':
    'Kunst-Blog | Nachrichten, Neuerscheinungen, Kunstveranstaltungen | Rise Art',
  'meta.blogCategory.title':
    'Kunst-Blog | Nachrichten, Neuerscheinungen, Kunstveranstaltungen | Rise Art',
  'meta.blogCategory.keywords':
    'art blog, art news, art events, online art gallery, contemporary art, art history, art movements',
  'meta.blogCategory.description':
    'Entdecken Sie die neuesten Kunstnachrichten, aktuelle Neuerscheinungen und Veranstaltungen. Ob Sie sich nun über Neuigkeiten aus der Kunstwelt auf dem Laufenden halten wollen, mehr Einblick in die Kunstgeschichte gewinnen möchten oder sich für die wichtigsten Kunstströmungen der Szene interessieren – im Rise Art Blog finden Sie alles, was Sie über zeitgenössische Kunst wissen müssen.',
  'meta.blogCategory.dynamicMetaTitle':
    '{title} | Nachrichten, Neuerscheinungen, Kunstveranstaltungen | Rise Art',
  'meta.blogCategory.dynamicMetaKeywords':
    '{title}, Kunst-Blog, Kunstnachrichten, Kunstveranstaltungen, Online-Kunstgalerie, zeitgenössische Kunst, Kunstgeschichte, Kunstströmungen',
  'meta.blogCategory.dynamicMetaDescription':
    'Entdecken Sie die neuesten {title}, Kunstnachrichten und Veranstaltungen. Ob Sie sich nun über Neuigkeiten in der Kunstwelt auf dem Laufen halten wollen, mehr Einblicke in die Kunstgeschichte gewinnen möchten oder sich für die wichtigsten Kunstströmungen der Szene interessieren – im Rise Art Blog finden Sie alles, was Sie über {title} wissen müssen.',
  'meta.blogArticle.title':
    'Kunst-Blog | Nachrichten, Neuerscheinungen, Kunstveranstaltungen | Rise Art',
  'meta.blogArticle.keywords':
    'art blog, art news, art events, online art gallery, contemporary art, art history, art movements',
  'meta.blogArticle.description':
    'Entdecken Sie die neuesten Kunstnachrichten, aktuelle Neuerscheinungen und Veranstaltungen. Ob Sie sich nun über Neuigkeiten aus der Kunstwelt auf dem Laufenden halten wollen, mehr Einblick in die Kunstgeschichte gewinnen möchten oder sich für die wichtigsten Kunstströmungen der Szene interessieren – im Rise Art Blog finden Sie alles, was Sie über zeitgenössische Kunst wissen müssen.',
  'meta.blogArticle.dynamicMetaTitle': '{title} | Rise Art',
  'meta.blogArticle.dynamicMetaKeywords':
    '{title}, {tags}, Kunstartikel, Kunst-Blog, Kunstnachrichten, Kunstveranstaltungen, neueste Nachrichten, Neuerscheinungen, Online-Kunstgalerie',
  'meta.blogArticle.dynamicMetaDescription': '{summary}',
  'meta.guide.pageTitle':
    'Kunst-Blog | Nachrichten, Neuerscheinungen, Kunstveranstaltungen | Rise Art',
  'meta.guide.title': 'Kunst-Blog | Nachrichten, Neuerscheinungen, Kunstveranstaltungen | Rise Art',
  'meta.guide.keywords':
    'art blog, art news, art events, online art gallery, contemporary art, art history, art movements',
  'meta.guide.description':
    'Entdecken Sie die neuesten und besten Kunstnachrichten, Neuerscheinungen und Veranstaltungen in unserem Rise Art Blog. Unser Kunst-Blog ist eine Anlaufstelle, um mehr über Kunst zu erfahren. Wir veröffentlichen tolle Beiträge zu vielen Themen, vom Styling von Kunst zu Hause bis hin zu einem Blick in die Ateliers unserer Künstler.',
  'meta.guideCategory.pageTitle':
    'Kunst-Blog | Nachrichten, Neuerscheinungen, Kunstveranstaltungen | Rise Art',
  'meta.guideCategory.title':
    'Kunst-Blog | Nachrichten, Neuerscheinungen, Kunstveranstaltungen | Rise Art',
  'meta.guideCategory.keywords':
    'art blog, art news, art events, online art gallery, contemporary art, art history, art movements',
  'meta.guideCategory.description':
    'Entdecken Sie die neuesten und besten Kunstnachrichten, Neuerscheinungen und Veranstaltungen in unserem Rise Art Blog. Unser Kunst-Blog ist eine gute Anlaufstelle, um mehr über Kunst zu erfahren. Wir veröffentlichen tolle Beiträge zu vielen Themen, vom Styling von Kunst zu Hause bis hin zu einem Blick in die Ateliers unserer Künstler.',
  'meta.guideCategory.dynamicMetaTitle':
    '{title} | Nachrichten, Neuerscheinungen, Kunstveranstaltungen | Rise Art',
  'meta.guideCategory.dynamicMetaKeywords':
    '{title}, Kunst-Blog, Kunstnachrichten, Kunstveranstaltungen, Online-Kunstgalerie, zeitgenössische Kunst, Kunstgeschichte, Kunstströmungen',
  'meta.guideCategory.dynamicMetaDescription':
    'Entdecken Sie die neuesten {title} Kunstnachrichten, Neuerscheinungen und Veranstaltungen in unserem Rise Art Blog. Unser Kunst-Blog ist eine gute Anlaufstelle, um mehr über Kunst zu erfahren. Wir veröffentlichen tolle Beiträge zu vielen Themen, von {title} bis zu einem Blick in die Ateliers unserer Künstler.',
  'meta.guideArticle.title':
    'Kunst-Blog | Nachrichten, Neuerscheinungen, Kunstveranstaltungen | Rise Art',
  'meta.guideArticle.keywords':
    'art blog, art news, art events, online art gallery, contemporary art, art history, art movements',
  'meta.guideArticle.description':
    'Entdecken Sie die neuesten und besten Kunstnachrichten, Neuerscheinungen und Veranstaltungen in unserem Rise Art Blog. Unser Kunst-Blog ist eine gute Anlaufstelle, um mehr über Kunst zu erfahren. Wir veröffentlichen tolle Beiträge zu vielen Themen, vom Styling von Kunst zu Hause bis hin zu einem Blick in die Ateliers unserer Künstler.',
  'meta.guideArticle.dynamicMetaTitle': '{title} | Rise Art',
  'meta.guideArticle.dynamicMetaDescription':
    'Entdecken Sie die neuesten {title} Kunstnachrichten, Neuerscheinungen und Veranstaltungen in unserem Rise Art Blog. Unser Kunst-Blog ist eine gute Anlaufstelle, um mehr über Kunst zu erfahren. Wir veröffentlichen tolle Beiträge zu vielen Themen, von {title} bis zu einem Blick in die Ateliers unserer Künstler.',
  'meta.guideArticle.dynamicMetaKeywords':
    '{title}, {tags}, Kunstartikel, Kunst-Blog, Kunstnachrichten, Kunstveranstaltungen, neueste Nachrichten, Neuerscheinungen, Online-Kunstgalerie',
  'meta.event.pageTitle':
    'Anstehende Ausstellungen und Veranstaltungen von Kunstgalerien entdecken',
  'meta.event.title':
    'Kunstveranstaltungen | Anstehende Kunst- und Galerieveranstaltungen | Rise Art',
  'meta.event.keywords':
    'Rent Art, Buy Art, Art, Limited Edition Prints, Posters, Artist, Emerging Artist, Emerging Art, Discover Art, Purchase Art, Buy Art, Original Art, Social, Artist Community, Art Blog, Contemporary Art, Graduate Art, Curated Art, Exclusive Art, Affordable Art, Original Art',
  'meta.event.description':
    'Alle Kunstveranstaltungen. Entdecken Sie anstehende Ausstellungen und Veranstaltungen von Galerien. Entdecken Sie mit Rise Art Originalkunst von talentierten Künstlerinnen und Künstlern. Mieten und kaufen Sie erschwingliche Kunst aus einer von Experten kuratierten Sammlung von Gemälden, Fotografien und Kunstdrucken in limitierter Auflage.',
  'meta.event.dynamicMetaTitle': '{name} | Kunstveranstaltung | Rise Art',
  'meta.event.dynamicMetaDescription':
    'Bekunden Sie Ihr Interesse und erhalten Sie Updates zu {name} in {address} am {date}. Entdecken Sie mit Rise Art anstehende Kustveranstaltungen und neue Kunst von talentierten Künstlerinnen und Künstlern. Mieten und kaufen Sie erschwingliche Kunst aus einer von Experten kuratierten Sammlung von Gemälden, Fotografien und Kunstdrucken in limitierter Auflage.',
  'meta.event.dynamicMetaKeywords':
    'Kunst mieten, Kunst kaufen, Kunst, Kunstdrucke in limitierter Auflage, Poster, Künstler, Nachwuchskünstler, aufkommende Kunst, Kunst entdecken, Kunst erwerben, Kunst kaufen, Originalkunst, sozial, Künstlergemeinschaft, Kunst-Blog, zeitgenössische Kunst, Graduiertenkunst, kuratierte Kunst, exklusive Kunst, erschwingliche Kunst, Originalkunst',
  'meta.eventList.pageTitle':
    'Anstehende Ausstellungen und Veranstaltungen von Kunstgalerien entdecken und Ihr Interesse bekunden',
  'meta.eventList.title':
    'Kunstveranstaltungen | Anstehende Kunst- und Galerieveranstaltungen | Rise Art',
  'meta.eventList.keywords':
    'Rent Art, Buy Art, Art, Limited Edition Prints, Posters, Artist, Emerging Artist, Emerging Art, Discover Art, Purchase Art, Buy Art, Original Art, Social, Artist Community, Art Blog, Contemporary Art, Graduate Art, Curated Art, Exclusive Art, Affordable Art, Original Art',
  'meta.eventList.description':
    'Alle Kunstveranstaltungen. Entdecken Sie anstehende Ausstellungen und Veranstaltungen von Galerien und bekunden Sie Ihr Interesse. Entdecken Sie mit Rise Art Originalkunst von talentierten Künstlerinnen und Künstlern. Mieten und kaufen Sie erschwingliche Kunst aus einer von Experten kuratierten Sammlung von Gemälden, Fotografien und Kunstdrucken in limitierter Auflage.',
  'meta.collectionList.pageTitle':
    'Entdecken Sie Kunstsammlungen. Zeitgenössische Kunst online kaufen. Online-Kunstgalerie.',
  'meta.collectionList.title': 'Kuratierte Kunstsammlungen | Kunst online kaufen | Rise Art',
  'meta.collectionList.keywords':
    'art collections, art themed collections, buy art online, original art, art, online art gallery, artists, contemporary art, art advisory, art for sale',
  'meta.collectionList.description':
    'Entdecken Sie kuratierte Kunstsammlungen auf Rise Art. Stöbern Sie in unserer Online-Kunstgalerie mit einer Vielzahl an Kunstsammlungen, die von unseren Kunstexperten fachmännisch kuratiert wurden. Kaufen Sie vertrauensvoll Kunst online mit kostenloser Kunstberatung.',
  'meta.collectionDetails.pageTitle':
    'Kunstsammlung. Zeitgenössische Kunst online kaufen. Online-Kunstgalerie.',
  'meta.collectionDetails.title': 'Kunst online kaufen | Rise Art',
  'meta.collectionDetails.keywords':
    'art collections, art themed collections, buy art online, original art, art, online art gallery, artists, contemporary art, art advisory, art for sale',
  'meta.collectionDetails.description':
    'Entdecken Sie kuratierte Kunstsammlungen auf Rise Art. Wir sind eine Online-Kunstgalerie, weshalb jedes Kunstwerk, das Sie kaufen können, von unseren Kuratoren fachmännisch geprüft wurde. Kaufen Sie vertrauensvoll Kunst online, mit kostenloser Kunstberatung und 14 Tagen kostenlosem Rückgaberecht.',
  'meta.collectionDetails.dynamicMetaTitle': '{name} | Kunst online kaufen | Rise Art',
  'meta.collectionDetails.dynamicPageTitle':
    '{name} Kunstsammlung auf Rise Art. Online-Kunstgalerie.',
  'meta.collectionDetails.dynamicMetaDescription':
    'Entdecken Sie die {name} Kunstsammlung auf Rise Art. Stöbern Sie in unserer Online-Kunstgalerie mit einer Vielzahl an Kunstsammlungen, die von unseren Kunstexperten fachmännisch kuratiert wurden. Kaufen Sie vertrauensvoll Kunst online mit kostenloser Kunstberatung.',
  'meta.myWishlist.pageTitle':
    'Kaufen Sie Kunst online zu erschwinglichen Preisen. Online-Kunstgalerie.',
  'meta.myWishlist.title': 'Ihr Wunschzettel | Kunst online kaufen | Rise Art',
  'meta.myWishlist.keywords':
    'art, limited edition prints, posters, artist, emerging artist, emerging art, discover art, purchase art, buy art, original art, social, artist community, art blog, select artists',
  'meta.myWishlist.description':
    'Betrachten und bearbeiten Sie Ihren Kunst-Wunschzettel. Entdecken Sie zeitgenössische Kunst, von Experten handverlesen. Entdecken Sie erschwingliche Originalkunst, die Sie kaufen können, darunter Gemälde, Fotografien und Kunstdrucke in limitierter Auflage.',
  'meta.myArtists.pageTitle':
    'Kaufen Sie Kunst online zu erschwinglichen Preisen. Online-Kunstgalerie.',
  'meta.myArtists.title': 'Meine Künstler | Rise Art',
  'meta.myArtists.keywords':
    'art, limited edition prints, posters, artist, emerging artist, emerging art, discover art, purchase art, buy art, original art, social, artist community, art blog, select artists',
  'meta.myArtists.description':
    'Betrachten und bearbeiten Sie die von Ihnen verfolgten Künstler. Entdecken Sie zeitgenössische Kunst, die von Experten handverlesen wurde. Erschwingliche Originalkunst zum Verkauf, darunter Gemälde, Fotografie und Drucke in limitierter Auflage.',
  'meta.myOffers.pageTitle': 'Kunst online kaufen zu günstigen Preisen. Online Kunstgalerie.',
  'meta.myOffers.title': 'Meine Angebote | Rise Art',
  'meta.myOffers.keywords':
    'Kunst, limitierte Drucke, Poster, Künstler, aufstrebende Künstler, aufstrebende Kunst, Kunst entdecken, Kunst kaufen, Kunst kaufen, Originalkunst, sozial, Künstlergemeinschaft, Kunstblog, Künstler auswählen',
  'meta.myOffers.description':
    'Sehen Sie sich Ihre Kunstangebote an. Entdecken Sie zeitgenössische Kunst, handverlesen von Experten. Erschwingliche Originalkunst zum Verkauf, darunter Gemälde, Fotografien und Drucke in limitierter Auflage.',
  'meta.myOffer.pageTitle': 'Kunst online kaufen zu günstigen Preisen. Online Kunstgalerie.',
  'meta.myOffer.title': 'Mein Angebot | Rise Art',
  'meta.myOffer.keywords':
    'Kunst, limitierte Drucke, Poster, Künstler, aufstrebende Künstler, aufstrebende Kunst, Kunst entdecken, Kunst kaufen, Kunst kaufen, Originalkunst, sozial, Künstlergemeinschaft, Kunstblog, Künstler auswählen',
  'meta.myOffer.description':
    'Sehen Sie sich die Details Ihres Kunstangebots an. Entdecken Sie zeitgenössische Kunst, handverlesen von Experten. Erschwingliche Originalkunst zum Verkauf, darunter Gemälde, Fotografie und Drucke in limitierter Auflage.',
  'meta.quiz.pageTitle':
    'Entdecken Sie Ihre Kunstpersönlichkeit auf Rise Art. Machen Sie unseren kostenlosen Persönlichkeitstest.',
  'meta.quiz.title': 'Kunst-Persönlichkeitstest | Entdecken Sie Ihren Kunststil | Rise Art',
  'meta.quiz.keywords':
    'personality test, free personality test, art, art quiz, art personality quiz',
  'meta.quiz.description':
    'Was sagt die von Ihnen bevorzugte Kunst über Ihre Persönlichkeit aus? Machen Sie unseren kostenlosen Kunst-Persönlichkeitstest und finden Sie heraus, was Ihre visuellen Vorlieben über Sie verraten.',
  'meta.quiz.ogTitle': 'Der Rise Art-Persönlichkeitstest',
  'meta.quiz.ogDescription':
    'Machen Sie den Test und finden Sie heraus, ob wir die Kunst auf Ihre Persönlichkeit abstimmen können. Wir haben den Test auf der Grundlage verhaltenspsychologischer Forschungsergebnisse entwickelt.',
  'meta.quizQuestions.pageTitle':
    'Entdecken Sie Ihre Kunstpersönlichkeit auf Rise Art. Machen Sie unseren kostenlosen Persönlichkeitstest.',
  'meta.quizQuestions.title':
    'Kunst-Persönlichkeitstest | Entdecken Sie Ihren Kunststil | Rise Art',
  'meta.quizQuestions.keywords':
    'personality test, free personality test, art, art quiz, art personality quiz',
  'meta.quizQuestions.description':
    'Was sagt die von Ihnen bevorzugte Kunst über Ihre Persönlichkeit aus? Machen Sie unseren kostenlosen Kunst-Persönlichkeitstest und finden Sie heraus, was Ihre visuellen Vorlieben über Sie verraten.',
  'meta.quizResult.pageTitle': 'Ihre Kunstpersönlichkeit und Ihr Kunstgeschmack',
  'meta.quizResult.title': 'Ihre Kunstpersönlichkeit und Ihr Kunstgeschmack | Rise Art',
  'meta.quizResult.keywords':
    'affordable art, rent art, buy art, art quiz, art style, art style quiz',
  'meta.quizResult.description':
    'Erhalten Sie persönliche Empfehlungen von unseren Kunstberatern, die auf Ihren Kunstgeschmack abgestimmt sind. Kaufen Sie Original-Kunst online von führenden Museen, Galerien und Künstlern. Entdecken Sie Original-Kunstwerke, die Sie kaufen können und die Ihrem Geschmack entsprechen',
  'meta.quizProfile.pageTitle':
    'Entdecken Sie Ihre Kunstpersönlichkeit auf Rise Art. Machen Sie unseren kostenlosen Persönlichkeitstest.',
  'meta.quizProfile.title': 'Kunst-Persönlichkeitstest | Rise Art',
  'meta.quizProfile.keywords':
    'personality test, free personality test, art, art quiz, art personality quiz',
  'meta.quizProfile.description':
    'Was sagt die von Ihnen bevorzugte Kunst über Ihre Persönlichkeit aus? Machen Sie unseren kostenlosen Kunst-Persönlichkeitstest und finden Sie heraus, was Ihre visuellen Vorlieben über Sie verraten.',
  'meta.quizProfile.dynamicMetaTitle': '{name} | Kunst-Persönlichkeitstest | Rise Art',
  'meta.quizProfile.dynamicPageTitle':
    '{name}. Kunst-Persönlichkeitstest. Machen Sie unseren kostenlosen Persönlichkeitstest.',
  'meta.quizProfile.dynamicMetaDescription':
    'Ich bin {name}. Was sagt die von Ihnen bevorzugte Kunst über Ihre Persönlichkeit aus? Machen Sie unseren Kunst-Persönlichkeitstest und finden Sie heraus, was Ihre visuellen Vorlieben über Sie verraten.',
  'meta.quizRegister.pageTitle':
    'Entdecken Sie Ihre Kunstpersönlichkeit auf Rise Art. Machen Sie unseren kostenlosen Test zum Kunststil.',
  'meta.quizRegister.title': 'Entdecken Sie Ihren Kunststil | Test zum Kunstgeschmack | Rise Art',
  'meta.quizRegister.keywords':
    'affordable art, rent art, buy art, art quiz, art style, art style quiz',
  'meta.quizRegister.description':
    'Erhalten Sie persönliche Empfehlungen von unseren Kunstberatern, die auf Ihren Kunstgeschmack abgestimmt sind. Kaufen Sie Original-Kunst online von führenden Museen, Galerien und Künstlern. Entdecken Sie Original-Kunstwerke, die Sie kaufen können und die Ihrem Geschmack entsprechen',
  'meta.quizLogin.pageTitle':
    'Entdecken Sie Ihre Kunstpersönlichkeit auf Rise Art. Machen Sie unseren kostenlosen Test zum Kunststil.',
  'meta.quizLogin.title': 'Entdecken Sie Ihren Kunststil | Test zum Kunstgeschmack | Rise Art',
  'meta.quizLogin.keywords':
    'affordable art, rent art, buy art, art quiz, art style, art style quiz',
  'meta.quizLogin.description':
    'Erhalten Sie persönliche Empfehlungen von unseren Kunstberatern, die auf Ihren Kunstgeschmack abgestimmt sind. Kaufen Sie Original-Kunst online von führenden Museen, Galerien und Künstlern. Entdecken Sie Original-Kunstwerke, die Sie kaufen können und die Ihrem Geschmack entsprechen',
  'meta.cart.title': 'Warenkorb | Rise Art',
  'meta.checkout.title': 'Zur Kasse | Rise Art',
  'meta.privacy.pageTitle': 'Meine Datenschutzeinstellungen',
  'meta.privacy.title': 'Meine Datenschutzeinstellungen | Rise Art',
  'meta.profile.pageTitle': 'Mein Profil',
  'meta.profile.title': 'Meine Profil | Rise Art',
  'meta.account.pageTitle': 'Mein Konto',
  'meta.account.title': 'Mein Konto | Rise Art',
  'meta.login.pageTitle': 'Anmelden bei Rise Art',
  'meta.login.title': 'Anmelden | Rise Art',
  'meta.register.pageTitle': 'Registrierung bei Rise Art',
  'meta.register.title': 'Registrierung | Rise Art',
  'meta.forgotPassword.pageTitle': 'Passwort vergessen',
  'meta.forgotPassword.title': 'Passwort vergessen | Rise Art',
  'meta.resetPassword.pageTitle': 'Kennwort ändern',
  'meta.resetPassword.title': 'Kennwort ändern | Rise Art',
  'meta.signout.pageTitle': 'Abmeldung von Rise Art',
  'meta.signout.title': 'Abmelden | Rise Art',
  'meta.search.pageTitle': 'Suchen',
  'meta.search.title': 'Suchen | Rise Art',
  'meta.error.pageTitle':
    'Kaufen Sie Kunst online zu erschwinglichen Preisen. Online-Kunstgalerie.',
  'meta.error.title': 'Kunst online kaufen | Erschwingliche Kunst zum Kauf | Rise Art',
  'meta.error.keywords':
    'buy art online, affordable art, original art, art, unique gifts, limited edition prints, rent art, artists, contemporary art, museums',
  'meta.error.description':
    'Kaufen Sie online erschwingliche Kunst von führenden Museen, Galerien und Künstlern. Entdecken Sie erschwingliche Originalkunst, die Sie kaufen können, darunter Gemälde, Fotografien und Drucke in limitierter Auflage.',
  'meta.common.pageTitle':
    'Kaufen Sie Kunst online zu erschwinglichen Preisen. Online-Kunstgalerie.',
  'meta.common.title': 'Kunst online kaufen | Erschwingliche Kunst zum Kauf | Rise Art',
  'meta.common.keywords':
    'art, limited edition prints, posters, artist, emerging artist, emerging art, discover art, purchase art, buy art, original art, social, artist community, art blog, select artists',
  'meta.common.description':
    'Rise Art ist eine Online-Community, in der jeder einzigartige Kunstwerke von aufstrebenden Talenten aus aller Welt entdecken, teilen und kaufen kann. Rise Art ermöglicht es den Nutzern, online direkt mit den Künstlern in Kontakt zu treten und mit ihren Werken zu interagieren. Wir sind eine exklusive Galerie, die kuratierte Werke zum Kauf anbietet.',
};

export default function Locale({ children }) {
  return children(messages);
}
